import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from './data.service';
import { Globle } from 'src/app/Shared/global';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PaginationModel } from 'src/Models/paginationModel';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(private _http: HttpClient, private _data: DataService, private _global: Globle) {
    this.setAPIUrl();
  }
  AppUrl: string = '';
  setAPIUrl() {
    if (
      sessionStorage.getItem('BaseURL') != null &&
      sessionStorage.getItem('BaseURL') != ''
    ) {
      this.AppUrl = sessionStorage.getItem('BaseURL') || '';
    }
  }

  getProviderLocation(Id: number, providerId: number, pagination: PaginationModel): Observable<any> {
    return this._data.post(`Providerlocation/GetProviderLocation/${Id}/${providerId}/`, pagination)
      .pipe(map(response => {
        return response;
      }));
  }


  getProviderLocationDropdown(providerId: number): Observable<any> {
    return this._data.get(`Providerlocation/ProviderLocationDropdown`, providerId)
      .pipe(map(response => {
        return response;
      }));
  }

  addupdatelocation(location): Observable<any> {
    return this._data.post(`Providerlocation/AddUpdateLocation`, location)
      .pipe(map(response => {
        return response;
      }));
  }

  AddZipCode(LocationZipcodeModel): Observable<any> {
    return this._data.post(`Providerlocation/AddZipCode`, LocationZipcodeModel)
      .pipe(map(response => {
        return response;
      }));
  }

  GetZipCodeList(LocationZipcodeSearchModel): Observable<any> {
    return this._data.post(`Providerlocation/GetZipCodeList`, LocationZipcodeSearchModel)
      .pipe(map(response => {
        return response;
      }));
  }

  DeleteZipCode(Id: number): Observable<any> {
    return this._data.get(`Providerlocation/DeleteZipCode`, Id)
      .pipe(map(response => {
        return response;
      }));
  }
}
