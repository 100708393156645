import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Http, Response, Headers, URLSearchParams, RequestOptions, ResponseContentType } from '@angular/http';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { DataService } from './data.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  AppUrl: string = '';

  constructor(private _http: HttpClient, private http: Http, private _data: DataService) {
    this.AppUrl = environment.apiURL;
  }

  InsertDocument(formData: FormData): Observable<any> {
    return this._data.postFromData(`document/insertdocument`, formData)
      .pipe(map(response => {
        return response;
      }));
  }

  GetDocuments(getdocumentModel): Observable<any> {
    return this._data.post(`document/getdocuments`, getdocumentModel)
      .pipe(map(response => {
        return response;
      }));
  }

  DeleteDocument(id: number): Observable<any> {
    return this._data.get(`document/deletedocument`, id)
      .pipe(map(response => {
        return response;
      }));
  }

  DownloadFiles(filePath: string, fileType: string): Observable<any> {
    let fileExtension = fileType;
    let input = this.AppUrl + "api/document/DownloadFile?fileName=" + filePath;
    return this.http.get(input, { responseType: ResponseContentType.Blob }).pipe(map(
      (res) => {
        return new Blob([res.blob()], { type: fileExtension });
      }));
  }
}
