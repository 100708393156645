import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataService } from './data.service';

@Injectable({
    providedIn: 'root'
})
export class ProviderZone {
    constructor(private _data: DataService) {
    }

    getProviderZoneDropDown(providerId, userId, editId): Observable<any> {

        let updateId = parseInt(editId);
        return this._data.post(`ProviderZone/GetProviderZoneDropDown/` + providerId + '/' + userId + '/' + updateId, { 'providerId': providerId, 'userId': userId, 'updateId': updateId })
            .pipe(map(response => {
                return response;
            }));
    }

    addUpdateZone(providerModel: any): Observable<any> {

        return this._data.post(`ProviderZone/AddUpdateZone`, providerModel)
            .pipe(map(response => {
                return response;
            }));
    }

    getProviderZoneDropDownByLocationId(providerId, userId, locationId): Observable<any> {
        return this._data.post(`ProviderZone/getProviderZoneDropDownByLocationId/` + providerId + '/' + userId + '/' + locationId, { 'providerId': providerId, 'userId': userId, 'locationId': locationId })
            .pipe(map(response => {
                return response;
            }));
    }

    ProviderZoneFindByZoneCode(providerId, zoneCode): Observable<any> {
        return this._data.post(`ProviderZone/ProviderZoneFindByZoneCode/` + providerId + '/' + zoneCode, { 'providerId': providerId, 'zoneCode': zoneCode })
            .pipe(map(response => {
                return response;
            }));
    }
}
