import { Component, OnInit } from '@angular/core';
import { UsersService } from 'src/Services/users.service';
import { PaginationModel } from 'src/Models/paginationModel';
import { Router } from '@angular/router';
import { EmailTemplateService } from 'src/Services/email-template.service';
import { Globle } from 'src/app/Shared/global';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-email-template',
  templateUrl: './email-template.component.html',
  styleUrls: ['./email-template.component.css']
})
export class EmailTemplateComponent implements OnInit {
  isloading: boolean;
  listOfEmailTemplate;
  totalRecordsCount = -1;
  Id = 0;
  providerId = 0;
  constructor(
    private emailTemplatService: EmailTemplateService,
    private router: Router,
    private globle: Globle,
    private titleService: Title,
  ) {
    this.titleService.setTitle('Email Templates');
  }

  ngOnInit() {
    this.providerId = this.globle.cUser.providerId;
    this.getEmailTemplateList();
  }

  getEmailTemplateList() {
    this.isloading = true;
    this.emailTemplatService.getEmailTemplate(this.providerId, this.globle.cUser.userTypeId).subscribe(data => {
      this.isloading = false;
      this.listOfEmailTemplate = data;
      this.totalRecordsCount = this.listOfEmailTemplate.length;
    }, error => {
      this.isloading = false;
    });
  }
  editemailTemplate(id: number) {
    this.router.navigate(['/admin/emailTemplate/edit/' + id]);
  }
}