import { Component, OnInit } from '@angular/core';
import { BillingService } from 'src/Services/billing.services';
import { Globle } from 'src/app/Shared/global';
import { BillingExports } from 'src/Models/billingExportsModel';
import { BillingFilterModel } from 'src/Models/billingFilterModel';
import { ProviderModel } from 'src/Models/providerModel';
import * as FileSaver from 'file-saver';
import { Title } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-billing-export',
  templateUrl: './billing-export.component.html',
  styleUrls: ['./billing-export.component.css']
})
export class BillingExportComponent implements OnInit {

  providerId: any = 0;
  isDataLoad: boolean = false;
  isProgress: boolean = false;

  listOfData: BillingExports[];
  filterModel = new BillingFilterModel();
  listOfProviders: ProviderModel[];

  bsRangeValue: Date[];
  exportStartDate: string = '';
  exportEndDate: string = '';

  constructor(private titleService: Title, private billingService: BillingService, private globle: Globle
    , private datePipe: DatePipe) {
    this.titleService.setTitle('Billing Export');
    let sDate = new Date();
    var firstDay = new Date(sDate.getFullYear(), sDate.getMonth(), 1);
    var lastDay = new Date(sDate.getFullYear(), sDate.getMonth() + 1, 0);
    this.bsRangeValue = [firstDay, lastDay];
  }

  ngOnInit() {
    this.GetProvidersForBilling();
  }

  BillingExport() {
    this.billingService.GetBillingExport(this.filterModel).subscribe(
      data => {
        this.listOfData = data;
        if (this.listOfData != null) {
          if (this.listOfData.length == 0)
            this.isDataLoad = true;
          else
            this.isDataLoad = false;
        }
      },
      error => {
        this.isProgress = false;
        console.log(error);
      }
    );
  }

  GetProvidersForBilling() {
    this.billingService.GetProvidersForBilling().subscribe(
      data => {
        this.listOfProviders = data;
        if (this.listOfProviders != null) {
          if (this.listOfProviders.length > 0) {
            this.providerId = this.listOfProviders[0].id;
            this.filterModel.providerId = this.providerId;
            this.BillingExport();
          }
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  setDateFormat($event) {
    let array = $event;
    if (array != null) {
      if (array.length > 0) {
        let dd = array[0].getDate().toString();
        if (parseInt(dd.toString()) < 10)
          dd = '0' + dd.toString();

        let mm = (array[0].getMonth() + 1).toString();
        if (parseInt(mm.toString()) < 10)
          mm = '0' + mm.toString();



        let dd1 = array[1].getDate().toString();
        if (parseInt(dd1.toString()) < 10)
          dd1 = '0' + dd1.toString();

        let mm1 = (array[1].getMonth() + 1).toString();
        if (parseInt(mm1.toString()) < 10)
          mm1 = '0' + mm1.toString();

        let sDate = array[0].getFullYear() + mm + dd;
        let eDate = array[1].getFullYear() + mm1 + dd1;
        this.exportStartDate = mm + '/' + dd + '/' + array[0].getFullYear()
        this.exportEndDate = mm1 + '/' + dd1 + '/' + array[1].getFullYear()

        this.filterModel.effStartDate = sDate;
        this.filterModel.effEndDate = eDate;

        if (this.providerId > 0)
          this.BillingExport();
      }
    }
  }

  exportToExcel() {
    let providerModel = null;
    if (this.listOfProviders != null) {
      if (this.listOfProviders.length > 0) {
        providerModel = this.listOfProviders.filter(m => m.id == this.providerId)
      }
    }

    var uri = 'data:application/vnd.ms-excel;base64,'
      , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body>{table}</body></html>'
      , base64 = function (s) { return window.btoa(unescape(encodeURIComponent(s))) }
      , format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) }
    let html = "<table><tr class='mainhead'> <td colspan='3'>Provider: " + ((providerModel != undefined && providerModel != null) ? providerModel[0].name : 'All') + "</td>";
    html += "</tr><tr class='mainhead'>    <td colspan='3'> From Year: " + this.exportStartDate + " To: " + this.exportEndDate + "</td>  </tr>";
    html += document.getElementById("reportHeader").innerHTML + " " + document.getElementById("reportBody").innerHTML;
    html += "</table>";
    let dt = this.transformDate(new Date());
    var ctx = { worksheet: "BillingExport_" + dt + ".xls" || 'Worksheet', table: html }
    FileSaver.saveAs((uri + base64(format(template, ctx))), "BillingExport_" + dt + ".xls");
  }


  transformDate(array) {
    if (array != null) {

      let dd = array.getDate().toString();
      if (parseInt(dd.toString()) < 10)
        dd = '0' + dd.toString();

      let mm = (array.getMonth() + 1).toString();
      if (parseInt(mm.toString()) < 10)
        mm = '0' + mm.toString();

      let hrs = array.getHours();
      if (parseInt(hrs.toString()) < 10)
        hrs = '0' + hrs.toString();

      let min = array.getMinutes()
      if (parseInt(min.toString()) < 10)
        min = '0' + min.toString();

      let sec = array.getSeconds()
      if (parseInt(sec.toString()) < 10)
        sec = '0' + sec.toString();

      return array.getFullYear() + '_' + mm + '_' + dd + '_' + hrs + '_' + min + '_' + sec;
    }
  }

}
