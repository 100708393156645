export class EligibilityCheckResponse {
    applicationId: string;
    eligibilityCheckId: string;
    status: string;
    eligibilityExpirationDate: string;
    activeSubscriber: string;
    _links: any;
    failures: any
    errorId: string;
    timestamp: string;
    message: string;
    errors: string;
    classJSON: string;
    postJSON: string;
    eligibilityJSON: string;
    userId: string;
    errorMessage: string;
    accessKey: string;
    name: string;
    accountNumber: string;
    emailId: string;
    ssn: string;
    enrollStatus: string;
    enrollStatusError: string;
    eliglibityLastStatusUpdateDate: string;
    displayName: string;
    enrollJSON: string;
    enrollmentDate: string;
}