import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, debounce } from 'rxjs/operators';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { DataService } from './data.service';
import { environment } from 'src/environments/environment';
import { Http, Response, ResponseContentType } from '@angular/http';
import { OnlineRegistrationModel } from 'src/Models/onlineRegistrationModel';
import { PaginationModel } from 'src/Models/paginationModel';

@Injectable({
    providedIn: 'root'
})
export class OnlineRegisterService {
    constructor(private _data: DataService) { }

    OnlineRegistrationAdd(registrationModel: OnlineRegistrationModel): Observable<any> {
        return this._data.post(`OnlineRegistration/OnlineRegistrationAdd`, registrationModel)
            .pipe(map(response => {
                return response;
            }));
    }

    GetOnlineRegistrationByPaged(pagination: PaginationModel): Observable<any> {
        return this._data.post(`OnlineRegistration/GetOnlineRegistrationByPaged`, pagination)
            .pipe(map(response => {
                return response;
            }));
    }

    GetOnlineRegistrationFindById(id: any = 0): Observable<any> {
        return this._data.post(`OnlineRegistration/GetOnlineRegistrationFindById/` + id, id)
            .pipe(map(response => {
                return response;
            }));
    }

    DeleteOnlineRegistrationFindById(id: any): Observable<any> {
        return this._data.post(`OnlineRegistration/DeleteOnlineRegistrationFindById/` + id, id)
            .pipe(map(response => {
                return response;
            }));
    }

    GetOnlineRegistrationByPagedExport(pagination: PaginationModel): Observable<any> {
        return this._data.post(`OnlineRegistration/GetOnlineRegistrationByPagedExport`, pagination)
            .pipe(map(response => {
                return response;
            }));
    }
}