import { Component, OnInit } from '@angular/core';
import { SubscriberService } from 'src/Services/subscriber.service';
import { DocumentService } from 'src/Services/document.service';
import { ToastrService } from 'src/app/Shared/toastr.service';
import { SubscriberApplicationStatus } from 'src/app/Shared/AppEnums';
import * as FileSaver from 'file-saver';
import { getIntParam, checkContainesURL, enumValue } from 'src/app/Shared/commonMethods';
import { ActivatedRoute, Router } from '@angular/router';
import { NewRegistrationModel } from 'src/Models/providerModel';
import { environment } from 'src/environments/environment';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Globle } from 'src/app/Shared/global';
import { AuthService } from 'src/app/Shared/auth.service';
import * as mime from 'mime';
import { defaultOptions } from 'ngx-extended-pdf-viewer';

@Component({
  selector: 'app-applicationreview',
  templateUrl: './applicationreview.component.html',
  styleUrls: ['./applicationreview.component.css', '../../../assets/css/Subscriber/panel.css', '../../../assets/css/status.css']
})
export class ApplicationreviewComponent implements OnInit {
  subscriberDetails: NewRegistrationModel = new NewRegistrationModel();
  subscriberId = 0;
  recertify: boolean;
  documentList = [];
  documentCount = 0;
  mimeType: string;
  submitted = false;
  applicationStatusForm: FormGroup;
  messageRequired = false;
  statusList;
  constructor
    (
      private subscriberService: SubscriberService,
      private documentService: DocumentService,
      private toastr: ToastrService,
      private activatedRoute: ActivatedRoute,
      private _fb: FormBuilder,
      private globle: Globle,
      private _authService: AuthService,
      private router: Router
    ) {
    defaultOptions.workerSrc = './assets/pdf.worker.js';
  }

  get applicationStatusformControl() { return this.applicationStatusForm.controls; }

  ngOnInit() {
    this.recertify = checkContainesURL(this.router, 'recertify');
    this.subscriberId = getIntParam(this.activatedRoute.params, 'id');
    this.getSubscriberDetails(this.subscriberId);
    this.applicationStatusForm = this._fb.group({
      id: [0],
      customerId: [""],
      locationId: [""],
      applicationStatus: [0],
      newapplicationStatus: [0, [Validators.min(1)]],
      message: [""],
    });
  }

  getSubscriberDetails(id: number) {
    this.subscriberService.GetSubscriberDetails(id).subscribe(data => {
      this.subscriberDetails = data.subscriberDetails;
      if (data.subscriberDetails.pdF_FilePath) {
        this.subscriberDetails.pdF_FilePath = environment.apiURL + data.subscriberDetails.pdF_FilePath;
      }
      this.documentCount = 0;
      if (data.documents.length > 0) {
        this.documentList = data.documents;
        this.documentCount = this.documentList.length;
      }
      this.filterStatusList();
    });
  }

  filterStatusList() {
    this.statusList = enumValue(SubscriberApplicationStatus);
    this.statusList = this.statusList.filter(x => x != SubscriberApplicationStatus[this.subscriberDetails.applicationStatus]);
  }

  changeStatus() {
    const statusId = +SubscriberApplicationStatus[this.applicationStatusForm.value['newapplicationStatus']];
    if (statusId == SubscriberApplicationStatus.Approve || statusId == SubscriberApplicationStatus.Reject
      || statusId == SubscriberApplicationStatus.Resubmission) {
      this.messageRequired = true;
    } else {
      this.messageRequired = false;
    }
  }

  updateApplicationStatus(applicationStatusForm: any) {
    this.submitted = true;
    if (!this._authService.checkValidation(this.applicationStatusForm)) {
      return false;
    }

    if (this.messageRequired && (!applicationStatusForm.value['message'] ||
      applicationStatusForm.value['message'].trim() == '')) {
      this.toastr.Error('Please enter message', 'Error');
      return false;
    }
    const statusId = +SubscriberApplicationStatus[this.applicationStatusForm.value['newapplicationStatus']];
    const subscriberApplicationStatusModel = {
      SubscriberId: this.subscriberId,
      ApplicationStatus: statusId,
      UserId: this.globle.cUser.id,
      subscriberMessageModel: {
        subscriberId: this.subscriberId,
        message: applicationStatusForm.value['message'],
        isManual: true,
        userId: this.globle.cUser.id
      }
    };
    this.subscriberService.UpdateApplicationStatus(subscriberApplicationStatusModel).subscribe(
      data => {
        if (data) {
          this.subscriberDetails.applicationStatus = statusId;
          this.toastr.Success('Application status has been updated sucessfully', 'Success');
          this.filterStatusList();
          this.applicationStatusformControl.message.setValue('');
          this.applicationStatusformControl.newapplicationStatus.setValue(0);
          this.submitted = false;
        }
      }
    );
  }

  getStringApplicationStatus(id: number): any {
    return SubscriberApplicationStatus[id];
  }

  downLoadFiles(url: string, fileName: string) {
    const fileType = mime.getType(fileName);
    this.documentService.DownloadFiles(url + fileName, fileType).subscribe(
      (res) => {
        FileSaver.saveAs(res, fileName);
      }
    );
  }

  cancel() {
    if (this.recertify) {
      this.router.navigate(['admin/recertify']);
    } else {
      this.router.navigate(['admin/newregistration']);
    }
  }
}