import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
@Pipe({ name: 'dateUTCToLocal' })
export class ConvertUTCToLocal implements PipeTransform {

    constructor(private datePipe: DatePipe) { }

    transform(value: Date, format: string): string {
        if (value) {
            return this.datePipe.transform(new Date(value.toString() + 'Z'), format);
        }
    }
}
