import { Injectable } from '@angular/core';
import { UserModel } from 'src/Models/usersModel';
import { SubscriberMasterModel, SubscriberApplicationHistory } from 'src/Models/SubscriberMaster.model';

@Injectable()
export class Globle {
    constructor() {
        this.cApp = new SubscriberMasterModel();
    }
    cUser: UserModel;
    cApp: SubscriberMasterModel;
}
