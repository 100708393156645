import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class ProviderService {
  constructor(private _data: DataService) {
  }

  GetProviders(pagination): Observable<any> {
    return this._data.post(`provider/GetProviders`, pagination)
      .pipe(map(response => {
        return response;
      }));
  }

  GetProvider(Id: any): Observable<any> {
    return this._data.get(`provider/GetProvider/` + Id)
      .pipe(map(response => {
        return response;
      }));
  }

  SaveProvider(providerModel: any): Observable<any> {
    return this._data.post(`provider/SaveProvider`, providerModel)
      .pipe(map(response => {
        return response;
      }));
  }

  GetRegistrationList(filterRegistrationModel: any): Observable<any> {
    return this._data.post(`provider/getregistrationlist`, filterRegistrationModel)
      .pipe(map(response => {
        return response;
      }));
  }
}
