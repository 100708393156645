import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class BillingService {

  constructor(private _data: DataService) { }

  GetLocationByProvider(ProviderId: any): Observable<any> {
    return this._data.get(`Billing/GetLocationByProvider`, ProviderId)
      .pipe(map(response => {
        return response;
      }));
  }

  SaveRate(model: any): Observable<any> {
    return this._data.post(`Billing/SaveRate`, model)
      .pipe(map(response => {
        return response;
      }));
  }

  GetRateSetupHistory(EffEndDate: any, ProviderId: any): Observable<any> {
    return this._data.post(`Billing/GetRateSetupHistory/` + EffEndDate + '/' + ProviderId, { 'EffEndDate': EffEndDate, 'ProviderId': ProviderId })
      .pipe(map(response => {
        return response;
      }));
  }

  GetRateSetupHistoryEffStartDate(ProviderId: any): Observable<any> {
    return this._data.post(`Billing/GetRateSetupHistoryEffStartDate/` + ProviderId, { 'ProviderId': ProviderId })
      .pipe(map(response => {
        return response;
      }));
  }

  GetBillingExport(filterModel: any): Observable<any> {
    return this._data.post(`Billing/GetBillingExport`, filterModel)
      .pipe(map(response => {
        return response;
      }));
  }

  GetProvidersForBilling(): Observable<any> {
    return this._data.get(`Billing/GetProvidersForBilling`)
      .pipe(map(response => {
        return response;
      }));
  }

  saveInvoice(model: any): Observable<any> {
    return this._data.post(`Billing/AddUpdateInvoice`, model)
      .pipe(map(response => {
        return response;
      }));
  }

  GetInvoiceByPaged(model: any): Observable<any> {
    return this._data.post(`Billing/GetInvoiceByPaged`, model)
      .pipe(map(response => {
        return response;
      }));
  }

  GetInvoiceFindById(Id: any): Observable<any> {
    return this._data.post(`Billing/GetInvoiceFindById/` + Id, Id)
      .pipe(map(response => {
        return response;
      }));
  }

  DeleteInvoice(Id: any): Observable<any> {
    return this._data.post(`Billing/DeleteInvoice/` + Id, Id)
      .pipe(map(response => {
        return response;
      }));
  }

}
