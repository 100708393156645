import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Login } from 'src/Models/loginModel';
import { DataService } from './data.service';
import { Globle } from 'src/app/Shared/global';
import { PaginationModel } from 'src/Models/paginationModel';
import { Http, ResponseContentType } from '@angular/http';

@Injectable({
  providedIn: 'root'
})
export class ConsentService {
  constructor(private _http: HttpClient, private _data: DataService, private _global: Globle, private http: Http) {
    this.setAPIUrl();
  }
  AppUrl: string = '';
  setAPIUrl() {
    if (
      sessionStorage.getItem('BaseURL') != null &&
      sessionStorage.getItem('BaseURL') != ''
    ) {
      this.AppUrl = sessionStorage.getItem('BaseURL') || '';
    }
  }

  GetSubscriberDetails(consentHistoryId: number, applicationHistoryId: number): Observable<any> {
    var model =
    {
      consentHistoryId: consentHistoryId,
      applicationHistoryId: applicationHistoryId
    }
    return this._data.post(`ViewConsent/GetSubscriberDetails`, model)
      .pipe(map(response => {
        return response;
      }));
  }

  CreatePDF(hTMLContent: string, chartImage: string, consentHistoryId: number, applicationHistoryId: number): Observable<any> {
    return this._data.post('ViewConsent/GetPDF', { hTMLContent: hTMLContent, chartImage: chartImage, consentHistoryId: consentHistoryId, applicationHistoryId: applicationHistoryId })
      .pipe(map(response => {
        return response;
      }));
  }

  DeletePDF(FileName: string): Observable<any> {
    return this._data.post('ViewConsent/DeletePDFFile/' + FileName, { fileName: FileName })
      .pipe(map(response => {
        return response;
      }));
  }

  public downloadPdf(url): Observable<any> {
    return this.http.get(url, { responseType: ResponseContentType.Blob }).pipe(map(
      (res) => {
        return new Blob([res.blob()], { type: 'application/pdf' });
      }));
  }
}
