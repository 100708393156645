export class PaginationModel {
  keywordFilter: string;
  pageNumber: number;
  pageSize: number;
  orderBy: string;
  fromDate: string;
  todate: string;
  locationId: number;
  whereCondition: string;
  apiStatus: string;
  providerId: string;
  userId: string;
}

