export enum AccountType {
  'Permanent',
  'Present'
}

export namespace AccountType {
  export function values() {
    return Object.keys(AccountType).filter(
      type => isNaN(<any>type) && type !== 'values'
    );
  }
}

export enum UserType {
  Admin = 1,
  Provider = 2,
  Subscriber = 3
}

export enum Month {
  'January' = 1,
  'February' = 2,
  'March' = 3,
  'April' = 4,
  'May' = 5,
  'June' = 6,
  'July' = 7,
  'August' = 8,
  'September' = 9,
  'October' = 10,
  'November' = 11,
  'December' = 12
}

export namespace Month {
  export function values() {
    return Object.keys(Month).filter(
      type => isNaN(<any>type) && type !== 'values'
    );
  }
}

export enum SubscriberApplicationStatus {
  'In Progress' = 0,
  'Pending' = 1,
  'In Review' = 2,
  'Reject' = 3,
  'Resubmission' = 4,
  'Approve' = 5,
  'Submit Documents' = 6
}

export enum DocumentType {
  'SubscriberDocument' = 1,
  'ProviderLogo' = 2
}

export enum FormType {
  'New Registration' = 1,
  'Re-Certify' = 2
}
export enum ProviderUserGroup {
  Admin = 3,
  Manager = 4,
  User = 5
}