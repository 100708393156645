import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { TreeNode } from 'primeng/api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class NodeService {

  constructor(private http: Http, private _data: DataService) { }

  getFiles(userTypeId: any) {
    return this.http.get(`Users/GetModules/` + userTypeId)
      .toPromise()
      .then(res => <TreeNode[]>res.json().data);
  }

}
