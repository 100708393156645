import { Component, OnInit } from '@angular/core';
import { BillingService } from 'src/Services/billing.services';
import { RateSetupModel } from 'src/Models/rateSetupModel';
import { Globle } from 'src/app/Shared/global';
import { Router, ActivatedRoute } from '@angular/router';
import { getIntParam } from 'src/app/Shared/commonMethods';
import { ToastrService } from 'src/app/Shared/toastr.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-rate-setup',
  templateUrl: './rate-setup.component.html',
  styleUrls: ['./rate-setup.component.css']
})
export class RateSetupComponent implements OnInit {
  providerId: any = 0;
  listOfProvider: RateSetupModel[];
  isDataLoad: boolean = false;
  isProgress: boolean = false;
  isUserClickButton: boolean = false;
  startMinDate;
  constructor(private titleService: Title, private billingService: BillingService,
    private router: Router, private activatedRoute: ActivatedRoute,
    private toastr: ToastrService, private globle: Globle) {
    this.titleService.setTitle('Rate Setup');
    this.providerId = getIntParam(this.activatedRoute.params, 'pid');
  }

  ngOnInit() {
    this.startMinDate = new Date();
    if (this.providerId > 0)
      this.GetLocationByProvider();
  }

  GetLocationByProvider() {
    this.isProgress = true;
    this.listOfProvider = [];
    this.billingService.GetLocationByProvider(this.providerId).subscribe(
      data => {
        this.isProgress = false;
        this.listOfProvider = (data);
        if (this.listOfProvider != null) {
          if (this.listOfProvider.length == 0) {
            this.isDataLoad = true;
          }
          else
            this.isDataLoad = false;
        }
        if (this.listOfProvider != null) {
          for (let iCounter = 0; iCounter < this.listOfProvider.length; iCounter++) {
            this.listOfProvider[iCounter].createdById = this.globle.cUser.id;
            if (this.listOfProvider[iCounter].startDate != null) {
              let dt = new Date(this.listOfProvider[iCounter].startDate)
              this.setDateFormat(dt, iCounter)
              this.listOfProvider[iCounter].startMinDate = dt;
            }
          }
        }
      },
      error => {
        this.isProgress = false;
        console.log(error);
      }
    )
  }

  saveRate() {
    this.isUserClickButton = true;
    let listOfRate = this.listOfProvider.filter(
      m =>
        (
          (m.annualLicenseFee != ('' || 0)) ||
          (m.initialAccessFee != ('' || 0)) ||
          (m.renewalFee != ('' || 0))
        ) &&
        (m.startDate != '' && m.startDate != null)
    );

    if (listOfRate != null) {
      for (let iCounter = 0; iCounter < listOfRate.length; iCounter++) {
        if (listOfRate[iCounter].maxEndDate != null) {
          if ((new Date(listOfRate[iCounter].startDate)).getTime() < (new Date(listOfRate[iCounter].maxEndDate)).getTime() && listOfRate[iCounter].isNullCount == 0) {
            this.toastr.Error("Please select higher date in " + listOfRate[iCounter].name + " ", "Error");
            this.isUserClickButton = false;
            return;
          }
        }
      }
    }

    if (listOfRate != null) {
      for (let iCounter = 0; iCounter < listOfRate.length; iCounter++) {
        if (listOfRate[iCounter].startDate != null) {
          let dt = new Date(listOfRate[iCounter].startDate)
          listOfRate[iCounter].startDate = this.setFinalDateFormat(dt, iCounter);
        }
      }
    }
    this.billingService.SaveRate(listOfRate).subscribe(
      data => {
        this.isUserClickButton = false;
        if (data == "Success") {
          this.toastr.Success("Record added successfully.", "Success");
          this.GetLocationByProvider();
        }
      },
      error => {
        this.isUserClickButton = false;
        console.log(error);
      }
    );
  }

  startDateFilter(startDate: any, index: any) {
    if (startDate != null) {
      this.setDateFormat(startDate, index);
    }
  }

  setDateFormat(startDate: Date, index: any) {
    if (startDate != null) {
      let dd1 = startDate.getDate().toString();
      if (parseInt(dd1.toString()) < 10)
        dd1 = '0' + dd1.toString();

      let mm1 = (startDate.getMonth() + 1).toString();
      if (parseInt(mm1.toString()) < 10)
        mm1 = '0' + mm1.toString();

      this.listOfProvider[index].startDate = startDate.getFullYear() + '-' + mm1 + '-' + dd1;
    }
  }

  setFinalDateFormat(startDate: Date, index: any) {
    if (startDate != null) {
      let dd1 = startDate.getDate().toString();
      if (parseInt(dd1.toString()) < 10)
        dd1 = '0' + dd1.toString();

      let mm1 = (startDate.getMonth() + 1).toString();
      if (parseInt(mm1.toString()) < 10)
        mm1 = '0' + mm1.toString();

      return (startDate.getFullYear() + '-' + mm1 + '-' + dd1);
    }
  }

  redirectToBack() {
    this.router.navigate(['/admin/providers']);
  }

  redirectToRateHistory() {
    this.router.navigate(['/admin/rate/history/' + this.providerId]);
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46) {
      return false;
    }
    return true;
  }
}
