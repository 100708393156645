export class AnalyticalReportFilterModel {
  LocationId: number;
  FromYear: number;
  ToYear: number;
  ReportOption: number;
  ApplicatioType: number
  Status: number;
  ViewBy: number;
}


export class AnalyticalReportModel {
  Total_Count: number;
  new_Approve_Count: number;
  reNew_Reject_Count: number;
  Data: Array<AnalyticalReportDataModel>;
}

export class AnalyticalReportDataModel {
  Name: string;
  New_Approve_Count: number;
  ReNew_Reject_Count: number;
}