import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, debounce } from 'rxjs/operators';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { DataService } from './data.service';
import { environment } from 'src/environments/environment';
import { Http, Response, ResponseContentType } from '@angular/http';

import {
  SubscriberPersonalDetailsModel,
  SubscriberAddressModel,
  SubscriberDependentModel,
  SubscriberQualifyModel,

  SubscriberAgreementModel
} from '../Models/subscribeModel';

@Injectable({
  providedIn: 'root'
})
export class SubscriberService {
  constructor(private _data: DataService) { }

  InsertPersonalDetails(subscriberPersonalDetailsModel): Observable<any> {
    return this._data.post(`subscriber/insertpersonaldetails`, subscriberPersonalDetailsModel)
      .pipe(map(response => {
        return response;
      }));
  }

  InsertAddress(subscriberAddressModel: SubscriberAddressModel): Observable<any> {
    return this._data.post(`subscriber/insertaddress`, subscriberAddressModel)
      .pipe(map(response => {
        return response;
      }));
  }

  InsertDependent(subscriberDependentModel: SubscriberDependentModel): Observable<any> {
    return this._data.post(`subscriber/insertdependent`, subscriberDependentModel)
      .pipe(map(response => {
        return response;
      }));
  }

  InsertQualify(subscriberQualifyModel: SubscriberQualifyModel): Observable<any> {
    return this._data.post(`subscriber/insertqualify`, subscriberQualifyModel)
      .pipe(map(response => {
        return response;
      }));
  }


  InsertAgreement(subscriberAgreementModel: SubscriberAgreementModel): Observable<any> {
    return this._data.post(`subscriber/insertagreement`, subscriberAgreementModel)
      .pipe(map(response => {
        return response;
      }));
  }

  GetCompletedStep(id: number): Observable<any> {
    return this._data.get(`subscriber/GetCompletedStep`, id)
      .pipe(map(response => {
        return response;
      }));
  }

  GetPersonalDetails(id: number, recertify: boolean): Observable<any> {
    return this._data.post(`subscriber/getpersonaldetails`, { id, recertify })
      .pipe(map(response => {
        return response;
      }));
  }

  GetSubscriberDetails(id: number): Observable<any> {
    return this._data.get(`provider/getSubscriberDetails`, id)
      .pipe(map(response => {
        return response;
      }));
  }

  GetAddress(subscriberId: number, recertify: boolean): Observable<any> {
    return this._data.post(`subscriber/getaddress`, { subscriberId, recertify })
      .pipe(map(response => {
        return response;
      }));
  }

  GetDependent(subscriberId: number): Observable<any> {
    return this._data.get(`subscriber/getdependent`, subscriberId)
      .pipe(map(response => {
        return response;
      }));
  }

  GetQualify(subscriberId: number): Observable<any> {
    return this._data.get(`subscriber/getqualify`, subscriberId)
      .pipe(map(response => {
        return response;
      }));
  }

  GetAgreement(subscriberId: number): Observable<any> {
    return this._data.get(`subscriber/getagreement`, subscriberId)
      .pipe(map(response => {
        return response;
      }));
  }

  SubmitForm(subscriberApplicationStatusModel): Observable<any> {
    return this._data.post(`subscriber/submitForm`, subscriberApplicationStatusModel)
      .pipe(map(response => {
        return response;
      }));
  }

  AddsubscriberUser(addsubscriberUserModel: any): Observable<any> {
    return this._data.post(`subscriber/addsubscriberuser`, addsubscriberUserModel)
      .pipe(map(response => {
        return response;
      }));
  }

  UpdateApplicationStatus(subscriberApplicationStatusModel): Observable<any> {
    return this._data.post(`subscriber/updateapplicationstatus`, subscriberApplicationStatusModel)
      .pipe(map(response => {
        return response;
      }));
  }

  GetMessage(subscriberId: number): Observable<any> {
    return this._data.get(`subscriber/getmessage`, subscriberId)
      .pipe(map(response => {
        return response;
      }));
  }

  GetMessageCount(SubscriberId: number): Observable<any> {
    return this._data.get(`subscriber/getmessagecount`, SubscriberId)
      .pipe(map(response => {
        return response;
      }));
  }

  AddMessage(addMessageModel: any): Observable<any> {
    return this._data.post(`subscriber/addmessage`, addMessageModel)
      .pipe(map(response => {
        return response;
      }));
  }

  CurrentApplication(): Observable<any> {
    return this._data.get(`subscriber/CurrentApplication`)
      .pipe(map(response => {
        return response;
      }));
  }

  NewCurrentApplication(): Observable<any> {
    return this._data.get(`subscriber/NewCurrentApplication`)
      .pipe(map(response => {
        return response;
      }));
  }

  ApplicationMessages(subscriberId: number): Observable<any> {
    return this._data.get(`subscriber/ApplicationMessages`, subscriberId)
      .pipe(map(response => {
        return response;
      }));
  }

  GenerateApplicationFormPDF(id: number, fileType: string): Observable<any> {
    return this._data.getFile(`pdf/applicationformpdf/`, id, fileType).pipe(map(
      (res) => {
        return new Blob([res.blob()], { type: fileType });
      }));
  }
}
