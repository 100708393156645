import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Globle } from 'src/app/Shared/global';
import { UsersService } from 'src/Services/users.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormGroup } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { AppConsts } from 'src/app/Shared/AppConsts';
import { StateService } from 'src/Services/state.service';
import { SubscriberMasterModel, SubscriberApplicationHistory } from 'src/Models/SubscriberMaster.model';
import { LocationService } from 'src/Services/location.service';
import { ProviderZoneModel } from 'src/Models/providerZoneModel';
import { ProviderZone } from 'src/Services/providerzone.services';
import { SubscriberService_ } from 'src/Services/subscriver_.service';
import { alphabetOnly } from 'src/app/Shared/commonMethods';
import { getIntParam, movetotop } from 'src/app/Shared/commonMethods';
import { OnlineRegisterService } from 'src/Services/onlineRegister.services';
import { OnlineRegistrationModel } from 'src/Models/onlineRegistrationModel';
import { ElementRef } from '@angular/core';
@Component({
  selector: 'app-add-transfer-in',
  templateUrl: './add-transfer-in.component.html',
  styleUrls: ['./add-transfer-in.component.css']
})
export class AddTransferInComponent implements OnInit {
  cApp = new SubscriberMasterModel();
  providerId = 0;
  userId: number = 0;
  // Personal Details
  personalDetailsForm: FormGroup;
  ssnValue: string = '';
  tinValue: string = '';
  isSubmittedPersonalDetails = false;
  // Address
  isSubmittedAddress = false;
  addressForm: FormGroup;
  statelist = [];
  stateShortNameTempValue: string = '';
  stateShortNameValue: string = '';
  isTribalLands: boolean = false;
  // Dependent
  isSubmittedDependant = false;
  isDependantSSN: boolean = true;
  dependentForm: FormGroup;
  startingUrl: string;
  maxDateValue = new Date();
  dependentSSNValue: string = '';
  dependentTINValue: string = '';
  isDependent: boolean = false;
  // Qualify
  isSubmittedQualify = false;
  notQualified: boolean = false;
  qualifyType: number = 1;
  qualifyForm: FormGroup;
  isTribalSpecific: boolean;
  isUncheckEBI: boolean = false;
  isSNAPChecked: boolean = false;
  isMedicalIdChecked: boolean = false;
  isSSIChecked: boolean = false;
  isFPHAChecked: boolean = false;
  isVPChecked: boolean = false;
  isBIAChecked: boolean = false;
  isTribalTANFChecked: boolean = false;
  isFDPIRChecked: boolean = false;
  isTribalHSChecked: boolean = false;
  isShowErrorMessage: boolean = false;
  isPEASAChecked: boolean = false;
  isSLBAChecked: boolean = false;
  isFPGChecked: boolean = false;
  isSLOIChecked: boolean = false;
  isELIPCovid19Checked: boolean = false;
  isWICChecked: boolean = false;
  // Other Information
  otherDetailForm: FormGroup;
  isSubmittedOtherDetails: boolean = false;
  public customPatterns = { '0': { pattern: new RegExp('\[a-zA-Z0-9\]') } };
  // Localtion
  dropdownSettings = {};
  selectedLocation = [];
  locationText = '';
  locations = [];
  locationList: any[] = [];
  // Zone
  listOfZone: ProviderZoneModel[];
  dropdownZoneList = [];
  selectedZoneItems = [];
  dropdownZoneSettings = {};
  zoneList: any[] = [];
  // Online Registration
  onlineRegistrationId: number = 0;
  registerModel = new OnlineRegistrationModel();
  // Confirm Reason Form
  confirmReasonForm: FormGroup;
  isAgree: boolean = false;
  singleProviderZone = new ProviderZoneModel();
  isEligiblityError: boolean = false;
  isEnrolledError: boolean = false;
  eligiblityErrorMessage: any;
  enrolledErrorMessage: any;
  isloading: boolean = false;
  subscriberApplicationData: any;
  badRequestError: any;
  @ViewChild('opneModal') opneModal: ElementRef;
  resubmitApplicationHistoryId: number = 0;
  isEnrolledRadioButtonDisable: boolean = false;
  isAlreadyEnrolledRadioChecked: boolean = false;
  isFrontEligibliityComplated: boolean = false;
  onlineApplicationHistoryId: number = 0;
  programName: string = 'ACP';
  constructor(private locationService: LocationService,
    private stateService: StateService, private fb: FormBuilder,
    private router: Router, private userService: UsersService,
    private titleService: Title,
    private providerZone: ProviderZone,
    private subscriberService: SubscriberService_,
    private globle: Globle, private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private onlineRegisterService: OnlineRegisterService,
  ) {
    this.providerId = this.globle.cUser.providerId;
    this.userId = this.globle.cUser.id;
    this.getState();
    this.onlineRegistrationId = getIntParam(this.activatedRoute.params, 'id');
    this.isFrontEligibliityComplated = sessionStorage.getItem('isFrontEligibliityComplated') == 'true' ? true : false;
    this.dropdownZoneSettings = {
      singleSelection: true,
      text: "Select Zone",
      selectAllText: 'Select All',
      unSelectAllText: 'Clear All',
      enableSearchFilter: true,
      enableCheckAll: true,
      badgeShowLimit: 1,
      showCheckbox: false,
      noDataLabel: 'No Zone available',
    };
    this.dropdownSettings = {
      singleSelection: true,
      text: "Select location",
      enableSearchFilter: true,
      classes: "dropdownborders",
      noDataLabel: 'No location available',
      showCheckbox: false,
      enableFilterSelectAll: false,
      escapeToClose: true
    };
    this.titleService.setTitle('Applicant - Add New');
    if (sessionStorage.getItem('applicationHistoryId') != null) {
      this.resubmitApplicationHistoryId = +sessionStorage.getItem('applicationHistoryId')
    }
    if (this.globle.cUser.userGroupId == 2 || this.globle.cUser.userGroupId == 5)
      this.isEnrolledRadioButtonDisable = true;
    else
      this.isEnrolledRadioButtonDisable = false;
  }
  ngOnInit() {
    this.personalDetailsFormInit();
    this.AddressFormInit();
    this.dependentFormInit();
    this.qualifyFormInit();
    this.otherDetailFormInit();
    this.getProviderLocation();
    this.confirmReasonFormInit();
    if (this.onlineRegistrationId > 0) {
      if (this.isFrontEligibliityComplated) {
        this.onlineApplicationHistoryId = +sessionStorage.getItem('isOnlineApplicationHistoryId');
        this.GetEligibilityApplicationFindByApplicationHistoryId()
      }
      else
        this.GetOnlineRegistrationFindById();
    }
    if (this.resubmitApplicationHistoryId > 0 && this.isFrontEligibliityComplated == false) {
      this.GetResumbitApplicationData();
    }
  }
  redirectToList() {
    if (sessionStorage.getItem('isOnlineApplicationHistoryId') != null) {
      sessionStorage.removeItem('isOnlineApplicationHistoryId');
    }
    this.router.navigate(['admin/applicants']);
  }
  get fpi() { return this.personalDetailsForm.controls; }
  get fad() { return this.addressForm.controls; }
  get fdp() { return this.dependentForm.controls; }
  get odf() { return this.otherDetailForm.controls; }
  personalDetailsFormInit() {
    this.personalDetailsForm = this.fb.group({
      firstName: ['', Validators.required],
      middleName: [''],
      lastName: ['', Validators.required],
      phoneNo: ['', Validators.required],
      dateofBirth: ['', Validators.required],
      emailId: ['', [Validators.pattern(AppConsts.EMAIL_REGEXP), Validators.required]],
      sSN: [''],
      tIN: [''],
      chkAgreement: [''],
      programName: this.programName,
    });
    this.personalDetailsForm.get('tIN').setValidators([]);
    this.personalDetailsForm.get('tIN').updateValueAndValidity();
    this.personalDetailsForm.get('tIN').setValue('');
  }
  onChangeSSN(type: string) {
    if (this.tinValue == '' && this.ssnValue == '') {
      this.personalDetailsForm.get('tIN').enable();
      this.personalDetailsForm.get('sSN').enable();
    }
    else if (this.ssnValue != '' && this.tinValue == '') {
      this.personalDetailsForm.get('tIN').disable();
      this.personalDetailsForm.get('sSN').enable();
    }
    else if (this.ssnValue == '' && this.tinValue != '') {
      this.personalDetailsForm.get('tIN').enable();
      this.personalDetailsForm.get('sSN').disable();
    }
    if (type == 'SSN' && this.tinValue == '') {
      this.personalDetailsForm.get('sSN').setValidators([Validators.required, Validators.minLength(4), Validators.maxLength(4)]);
      this.personalDetailsForm.get('tIN').setValidators([]);
      this.personalDetailsForm.get('sSN').updateValueAndValidity();
      this.personalDetailsForm.get('tIN').updateValueAndValidity();
      this.personalDetailsForm.get('tIN').setValue('');
    }
    else if (this.ssnValue == '') {
      this.personalDetailsForm.get('tIN').setValidators([Validators.required, Validators.minLength(2), Validators.maxLength(20)]);
      this.personalDetailsForm.get('sSN').setValidators([]);
      this.personalDetailsForm.get('tIN').updateValueAndValidity();
      this.personalDetailsForm.get('sSN').updateValueAndValidity();
      this.personalDetailsForm.get('sSN').setValue('');
    }
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  saveDetails(personalDetailsForm, addressForm, dependentForm, qualifyForm, otherDetailForm) {
    this.isEligiblityError = false;
    this.isEnrolledError = false;
    this.isSubmittedPersonalDetails = true;
    this.isSubmittedAddress = true;
    this.isSubmittedDependant = true;
    this.isSubmittedOtherDetails = true;
    if (this.personalDetailsForm.invalid) {
      return;
    }
    this.isSubmittedPersonalDetails = true;
    if (this.addressForm.invalid) {
      return;
    }
    if (this.isDependent) {
      if (this.dependentForm.invalid) {
        return;
      }
    }
    if (this.qualifyForm.invalid) {
      return;
    }
    if (this.confirmReasonForm.value['isEnrolledInNLAD'] == 'Yes') {
      if (this.otherDetailForm.invalid) {
        return;
      }
    }
    if (!alphabetOnly(addressForm.controls.cityTemp.value)) {
      this.toastr.error("Only alphbet allow in mail address city.", "Error");
      return;
    }
    if (otherDetailForm.controls.applicationNumber.value == '' || otherDetailForm.controls.applicationNumber.value == null) {
      this.toastr.error("Please enter application number.", "Error");
      return;
    }
    else {
      if (otherDetailForm.controls.applicationNumber.value.length < 11) {
        this.toastr.error("Invalid length of application number.", "Error");
        return;
      }
    }
    this.cApp.subscriberPersonalDetails = personalDetailsForm.value;
    this.cApp.subscriberAddress = addressForm.value;
    this.cApp.subscriberDependent = dependentForm.value;
    this.cApp.subscriberQualify = qualifyForm.value;
    this.cApp.subscriberAddress.stateShortName = this.stateShortNameValue;
    this.cApp.subscriberAddress.stateShortNameTemp = this.stateShortNameTempValue;
    this.cApp.subscriberApplicationHistory.userId = 0;
    this.cApp.subscriberApplicationHistory.approvalDate = null;
    this.cApp.subscriberApplicationHistory.createdDate = null;
    this.cApp.subscriberApplicationHistory.modifiedDate = null;
    if (this.cApp.subscriberAddress.addressType == 'Home') {
      this.cApp.subscriberAddress.stateShortNameTemp = this.stateShortNameTempValue;
      if (this.cApp.subscriberAddress.stateIdTemp == null || this.cApp.subscriberAddress.stateIdTemp == 0) {
        return;
      }
      if (!alphabetOnly(addressForm.controls.cityTemp.value)) {
        this.toastr.error("Only alphbet allow in mail city.", "Error");
        return;
      }
    }
    else {
      if (!alphabetOnly(addressForm.controls.city.value)) {
        this.toastr.error("Only alphbet allow in city.", "Error");
        return;
      }
      this.cApp.subscriberAddress.cityTemp = this.cApp.subscriberAddress.city;
      this.cApp.subscriberAddress.stateIdTemp = this.cApp.subscriberAddress.stateId;
      this.cApp.subscriberAddress.stateShortNameTemp = this.stateShortNameValue;
      this.cApp.subscriberAddress.streetNumberTemp = this.cApp.subscriberAddress.streetNumber;
      this.cApp.subscriberAddress.zipcodeTemp = this.cApp.subscriberAddress.zipcode;
    }
    this.cApp.subscriberAddress.isTribalLands = this.isTribalLands;
    let dt = new Date(this.cApp.subscriberPersonalDetails.dateofBirth);
    this.cApp.subscriberPersonalDetails.dateofBirth = this.setDateFormat(dt, false);
    if (this.isDependent) {
      dt = new Date(this.cApp.subscriberDependent.dateofBirth);
      this.cApp.subscriberDependent.dateofBirth = this.setDateFormat(dt, false);
    }
    let enrollDate = otherDetailForm.value.enrollmentDate;
    let locationId = this.locationList.join(',');
    let zone = this.zoneList.join(',');
    this.cApp.subscriberPersonalDetails.locationCode = otherDetailForm.value.accountNumber != null ? otherDetailForm.value.accountNumber.toString() : '';
    this.cApp.subscriberPersonalDetails.customerId = otherDetailForm.value.accountNumber != null ? otherDetailForm.value.accountNumber.toString() : '';
    this.cApp.subscriberPersonalDetails.applicationNumber = otherDetailForm.value.applicationNumber.toString();
    this.cApp.subscriberPersonalDetails.enrollDate = enrollDate;
    this.cApp.subscriberPersonalDetails.locationId = locationId;
    this.cApp.subscriberPersonalDetails.zone = zone;
    this.cApp.subscriberPersonalDetails.eligibilityExpirationDate = otherDetailForm.value.eligibilityExpirationDate;
    this.cApp.subscriberPersonalDetails.transactionEffectiveDate = otherDetailForm.value.transactionEffectiveDate;
    this.cApp.subscriberPersonalDetails.serviceInitializationDate = otherDetailForm.value.serviceInitializationDate;
    let dt1 = new Date(this.cApp.subscriberPersonalDetails.enrollDate);
    this.cApp.subscriberPersonalDetails.enrollDate = this.setDateFormat(dt1, true);
    dt1 = new Date(this.cApp.subscriberPersonalDetails.transactionEffectiveDate);
    this.cApp.subscriberPersonalDetails.transactionEffectiveDate = this.setDateFormat(dt1, true);
    dt1 = new Date(this.cApp.subscriberPersonalDetails.serviceInitializationDate);
    this.cApp.subscriberPersonalDetails.serviceInitializationDate = this.setDateFormat(dt1, true);
    dt1 = new Date(this.cApp.subscriberPersonalDetails.eligibilityExpirationDate);
    this.cApp.subscriberPersonalDetails.eligibilityExpirationDate = this.setDateFormat(dt1, true);
    this.cApp.subscriberPersonalDetails.onlineRegistrationID = this.onlineRegistrationId.toString();
    // Application Stage
    this.cApp.subscriberApplicarionStage.isPersonalDetailsCompleted = true;
    this.cApp.subscriberApplicarionStage.isAddressCompleted = true;
    this.cApp.subscriberApplicarionStage.isQualifiedCompleted = true;
    this.cApp.subscriberApplicarionStage.isAgreementCompleted = true;
    this.cApp.subscriberApplicarionStage.isReviewCompleted = true;
    // Other Details
    this.cApp.subscriberOthers.deviceMake = '';
    this.cApp.subscriberOthers.deviceReimbursementDate = null;
    this.cApp.subscriberOthers.deviceType = '';
    this.cApp.subscriberOthers.expectedRate = '';
    this.cApp.subscriberOthers.expectedRateDevice = '';
    this.cApp.subscriberOthers.latitude = '';
    this.cApp.subscriberOthers.longitude = '';
    this.cApp.subscriberOthers.schoolName = '';
    this.cApp.subscriberPersonalDetails.isEnrolledInNLAD = 'Yes';
    this.cApp.subscriberApplicationHistory.createdById = this.userId;
    this.cApp.subscriberApplicationHistory.modifiedById = this.userId;
    this.cApp.subscriberPersonalDetails.isAlreadyEnrolledRadioChecked = this.isAlreadyEnrolledRadioChecked;
    this.cApp.subscriberPersonalDetails.programName = 'ACP';
    this.cApp.subscriberPersonalDetails.isConsumerFee = otherDetailForm.value.isConsumerFee;
    this.isloading = true;
    if (this.cApp.subscriberPersonalDetails.customerId == "" || this.cApp.subscriberPersonalDetails.customerId == null) {
      this.cApp.subscriberPersonalDetails.customerId = "000000";
    }
    if (this.cApp.subscriberPersonalDetails.locationCode == "" || this.cApp.subscriberPersonalDetails.locationCode == null) {
      this.cApp.subscriberPersonalDetails.locationCode = "000000";
    }

    this.subscriberService.TransferInApplication(this.cApp).subscribe(data => {
      this.isloading = false;
      if (data.status == "Success") {
        this.toastr.success(data.error_Description, 'Success');
      }
      else {
        this.toastr.error(data.error_Description, 'Error');
      }

    },
      error => {
        this.isloading = false;
        console.log(error);
      }
    );
  }
  getState() {
    this.stateService.GetStateListEBB().subscribe(data => {
      if (data) {
        this.statelist = data;
      }
    }, error => {
    });
  }
  showTempAddress(value) {
    if (value === 'Home') {
      this.fad.streetNumberTemp.setValidators([Validators.required]);
      this.fad.cityTemp.setValidators([Validators.required]);
      this.fad.stateIdTemp.setValidators([Validators.required]);
      this.fad.zipcodeTemp.setValidators([Validators.required]);
    }
    else {
      this.fad.streetNumberTemp.clearValidators();
      this.fad.cityTemp.clearValidators();
      this.fad.stateIdTemp.clearValidators();
      this.fad.zipcodeTemp.clearValidators();
    }
    this.fad.streetNumberTemp.updateValueAndValidity();
    this.fad.cityTemp.updateValueAndValidity();
    this.fad.stateIdTemp.updateValueAndValidity();
    this.fad.zipcodeTemp.updateValueAndValidity();
  }
  AddressFormInit() {
    this.addressForm = this.fb.group({
      streetNumber: ['', Validators.required],
      city: ['', Validators.required],
      stateId: ['', Validators.required],
      zipcode: ['', Validators.required],
      stateShortName: [''],
      stateShortNameTemp: [''],
      streetNumberTemp: [''],
      cityTemp: [''],
      stateIdTemp: [''],
      zipcodeTemp: [''],
      addressType: ['Mail'],
      isTribalLands: [this.isTribalLands]
    });
  }
  getStateName(id, isMainStateShortName) {
    if (id) {
      var state = this.statelist.filter((x: any) => x.id == id)[0];
      if (state) {
        if (isMainStateShortName) {
          this.isTribalLands = state.isSelected
          this.stateShortNameValue = state.stateCode;
        }
        else {
          this.stateShortNameTempValue = state.stateCode;
        }
        return state.name;
      }
      return '';
    }
  }
  setShortName($event: any, isMainStateShortName: any) {
    this.getStateName($event.currentTarget.value, isMainStateShortName)
  }
  dependentFormInit() {
    this.dependentForm = this.fb.group({
      isDependent: [this.isDependent],
      firstName: ['', Validators.required],
      middleName: [''],
      lastName: ['', Validators.required],
      dateofBirth: ['', Validators.required],
      sSN: [this.dependentSSNValue],
      tIN: [this.dependentTINValue]
    });
    this.dependentForm.get('tIN').setValidators([]);
    this.dependentForm.get('tIN').updateValueAndValidity();
    this.dependentForm.get('tIN').setValue('');
  }
  onChangDependentSSN(type: string) {
    if (this.dependentTINValue == '' && this.dependentSSNValue == '') {
      this.dependentForm.get('tIN').enable();
      this.dependentForm.get('sSN').enable();
    }
    else if (this.dependentSSNValue != '' && this.dependentTINValue == '') {
      this.dependentForm.get('tIN').disable();
      this.dependentForm.get('sSN').enable();
    }
    else if (this.dependentSSNValue == '' && this.dependentTINValue != '') {
      this.dependentForm.get('tIN').enable();
      this.dependentForm.get('sSN').disable();
    }
    if (type == 'SSN' && this.dependentTINValue == '') {
      this.dependentForm.get('sSN').setValidators([Validators.required, Validators.minLength(4), Validators.maxLength(4)]);
      this.dependentForm.get('tIN').setValidators([]);
      this.dependentForm.get('sSN').updateValueAndValidity();
      this.dependentForm.get('tIN').updateValueAndValidity();
      this.dependentForm.get('tIN').setValue('');
    }
    else if (this.dependentSSNValue == '') {
      this.dependentForm.get('tIN').setValidators([Validators.required, Validators.minLength(2), Validators.maxLength(20)]);
      this.dependentForm.get('sSN').setValidators([]);
      this.dependentForm.get('tIN').updateValueAndValidity();
      this.dependentForm.get('sSN').updateValueAndValidity();
      this.dependentForm.get('sSN').setValue('');
    }
  }
  enableDisableDependentFields(eventChecked) {
    this.isDependent = eventChecked;
    this.dependentForm.get('isDependent').setValue(this.isDependent);
    if (eventChecked) {
      this.dependentForm.enable();
    }
  }
  qualifyFormInit() {
    this.qualifyForm = this.fb.group({
      qualifyType: ['1'],
      sNAP: [], // E2 = Supplemental Nutrition Assistance Program (SNAP) (Food Stamps)
      medicalId: [], // E1 = Medicaid
      sSI: [], // E3 = Supplemental Security Income (SSI)
      fPHA: [], // E4 = Federal Public Housing Assistance (FPHA)
      vP: [], // E15 = Veterans Pension or Survivors Benefit Programs
      bIA: [], // E8 = Bureau of Indian Affairs (BIA) General Assistance
      tribalTANF: [], // E9 = Tribal Temporary Assistance for Needy Families (Tribal TANF)
      fDPIR: [], // E10 = Food Distribution Program on Indian Reservations (FDPIR)
      tribalHS: [], // E11 = Tribal Head Start (only households that meet the income qualifying standard)
      eBI: [], // E13 = I don't participate in one of these programs, I want to qualify through my income.
      isTribalSpecific: false,
      pEASA: [], // E14 – Program Eligibility Approved by State Administrator 
      sLBA: [], // E50 – School Lunch/Breakfast Program
      fPG: [], // E51 – Federal Pell Grant
      sLOI: [], // E52 – Substantial Loss of Income
      eLIPCovid19: [], // E53 – Existing low-income program/COVID-19 program
      wIC: [], // E54 – Special Supplemental Nutrition Program for Women, Infants, and Children (WIC)
    });
  }
  uncheckEBI($event, flag: string) {
    if (flag == 'SNAP') {
      this.isSNAPChecked = $event.currentTarget.checked;
      if (this.isSNAPChecked)
        this.isUncheckEBI = false;
    }
    else if (flag == 'MedicalId') {
      this.isMedicalIdChecked = $event.currentTarget.checked;
      if (this.isMedicalIdChecked)
        this.isUncheckEBI = false;
    }
    else if (flag == 'SSI') {
      this.isSSIChecked = $event.currentTarget.checked;
      if (this.isSSIChecked)
        this.isUncheckEBI = false;
    }
    else if (flag == 'FPHA') {
      this.isFPHAChecked = $event.currentTarget.checked;
      if (this.isFPHAChecked)
        this.isUncheckEBI = false;
    }
    else if (flag == 'VP') {
      this.isVPChecked = $event.currentTarget.checked;
      if (this.isVPChecked)
        this.isUncheckEBI = false;
    }
    else if (flag == 'BIA') {
      this.isBIAChecked = $event.currentTarget.checked;
      if (this.isBIAChecked)
        this.isUncheckEBI = false;
    }
    else if (flag == 'TribalTANF') {
      this.isTribalTANFChecked = $event.currentTarget.checked;
      if (this.isTribalTANFChecked)
        this.isUncheckEBI = false;
    }
    else if (flag == 'FDPIR') {
      this.isFDPIRChecked = $event.currentTarget.checked;
      if (this.isFDPIRChecked)
        this.isUncheckEBI = false;
    }
    else if (flag == 'TribalHS') {
      this.isTribalHSChecked = $event.currentTarget.checked;
      if (this.isTribalHSChecked)
        this.isUncheckEBI = false;
    }
    else if (flag == 'pEASA') {
      this.isPEASAChecked = $event.currentTarget.checked;
      if (this.isPEASAChecked)
        this.isUncheckEBI = false;
    }
    else if (flag == 'sLBA') {
      this.isSLBAChecked = $event.currentTarget.checked;
      if (this.isSLBAChecked)
        this.isUncheckEBI = false;
    }
    else if (flag == 'fPG') {
      this.isFPGChecked = $event.currentTarget.checked;
      if (this.isFPGChecked)
        this.isUncheckEBI = false;
    }
    else if (flag == 'sLOI') {
      this.isSLOIChecked = $event.currentTarget.checked;
      if (this.isSLOIChecked)
        this.isUncheckEBI = false;
    }
    else if (flag == 'eLIPCovid19') {
      this.isELIPCovid19Checked = $event.currentTarget.checked;
      if (this.isELIPCovid19Checked)
        this.isUncheckEBI = false;
    }
    else if (flag == 'wIC') {
      this.isWICChecked = $event.currentTarget.checked;
      if (this.isWICChecked)
        this.isUncheckEBI = false;
    }
    else if (flag == 'EBI') {
      if (!this.isSNAPChecked && !this.isMedicalIdChecked && !this.isSSIChecked && !this.isFPHAChecked
        && !this.isVPChecked && !this.isBIAChecked && !this.isTribalTANFChecked && !this.isFDPIRChecked
        && !this.isTribalHSChecked && !this.isPEASAChecked && !this.isSLBAChecked && !this.isFPGChecked
        && !this.isSLOIChecked && !this.isELIPCovid19Checked && !this.isWICChecked
      ) {
        this.isUncheckEBI = $event.currentTarget.checked;
      }
      else {
        this.isUncheckEBI = false;
        this.qualifyForm.setValue({
          qualifyType: ['1'],
          sNAP: this.isSNAPChecked, // E2 = Supplemental Nutrition Assistance Program (SNAP) (Food Stamps)
          medicalId: this.isMedicalIdChecked, // E1 = Medicaid
          sSI: this.isSSIChecked, // E3 = Supplemental Security Income (SSI)
          fPHA: this.isFPHAChecked, // E4 = Federal Public Housing Assistance (FPHA)
          vP: this.isVPChecked, // E15 = Veterans Pension or Survivors Benefit Programs
          bIA: this.isBIAChecked, // E8 = Bureau of Indian Affairs (BIA) General Assistance
          tribalTANF: this.isTribalTANFChecked, // E9 = Tribal Temporary Assistance for Needy Families (Tribal TANF)
          fDPIR: this.isFDPIRChecked, // E10 = Food Distribution Program on Indian Reservations (FDPIR)
          tribalHS: this.isTribalHSChecked, // E11 = Tribal Head Start (only households that meet the income qualifying standard)
          eBI: this.isUncheckEBI, // E13 = I don't participate in one of these programs, I want to qualify through my income.
          isTribalSpecific: false,
          pEASA: this.isPEASAChecked, // E14 – Program Eligibility Approved by State Administrator 
          sLBA: this.isSLBAChecked, // E50 – School Lunch/Breakfast Program
          fPG: this.isFPGChecked, // E51 – Federal Pell Grant
          sLOI: this.isSLOIChecked, // E52 – Substantial Loss of Income
          eLIPCovid19: this.isELIPCovid19Checked, // E53 – Existing low-income program/COVID-19 program
          wIC: this.isWICChecked, // E54 – Special Supplemental Nutrition Program for Women, Infants, and Children (WIC)
        });
      }
    }
  }
  otherDetailFormInit() {
    this.otherDetailForm = this.fb.group({
      enrollmentDate: ['', Validators.required],
      applicationNumber: ['', Validators.required],
      accountNumber: [''],
      locationId: [],
      zone: [],
      transactionEffectiveDate: ['', Validators.required],
      serviceInitializationDate: ['', Validators.required],
      eligibilityExpirationDate: ['', Validators.required],
      isConsumerFee: [false]
    });
  }
  getProviderLocation(): void {
    this.locationService.getProviderLocationDropdown(this.providerId).subscribe(
      data => {
        if (data != null) {
          for (let iCounter = 0; iCounter < data.length; iCounter++) {
            this.locations.push({ 'id': data[iCounter].id, 'itemName': data[iCounter].name })
          }
        }
      }
    );
  }
  // Location
  onLocationSelect(item) {
    this.locationList = [];
    this.zoneList = []
    this.selectedZoneItems = []
    if (this.locationList != undefined) {
      this.locationList = this.removeElemetninArray(this.locationList, item['id']);
    }
    this.locationList.push(item['id']);
    this.getProviderZoneDropDown(this.locationList.join(','));
  }
  onLocationDeSelect(item) {
    this.locationList = [];
    this.zoneList = []
    this.selectedZoneItems = []
    if (this.locationList != undefined) {
      this.locationList = this.removeElemetninArray(this.locationList, item['id']);
    }
    this.getProviderZoneDropDown(this.locationList.join(','));
  }
  onLocationSelectAll(items) {
    this.locationList = [];
    this.zoneList = []
    this.selectedZoneItems = []
    for (let iCounter = 0; iCounter < items.length; iCounter++) {
      this.locationList.push(items[iCounter]['id']);
    }
    this.getProviderZoneDropDown(this.locationList.join(','));
  }
  onLocationDeSelectALL(items) {
    this.locationList = [];
    this.zoneList = []
    this.selectedZoneItems = []
    this.getProviderZoneDropDown(this.locationList.join(','));
  }
  getProviderZoneDropDown(id): void {
    this.providerZone.getProviderZoneDropDownByLocationId(this.providerId, this.userId, id).subscribe(
      data => {
        this.listOfZone = data;
        this.dropdownZoneList = [];
        if (this.listOfZone != null) {
          if (this.listOfZone.length > 0) {
            for (let iCounter = 0; iCounter < this.listOfZone.length; iCounter++) {
              this.dropdownZoneList.push(
                { "id": this.listOfZone[iCounter].id, "itemName": this.listOfZone[iCounter].zoneCode }
              );
            }
          }
        }
      }
    );
  }
  removeElemetninArray(array, element) {
    return array.filter(e => e != element);
  }
  // Zone 
  onZoneSelect(item) {
    this.zoneList = [];
    if (this.zoneList != undefined) {
      this.zoneList = this.removeElemetninArray(this.zoneList, item['id']);
    }
    this.zoneList.push(item['id']);
  }
  onZoneDeSelect(item) {
    this.zoneList = [];
    if (this.zoneList != undefined) {
      this.zoneList = this.removeElemetninArray(this.zoneList, item['id']);
    }
  }
  onZoneSelectAll(items) {
    this.zoneList = [];
    for (let iCounter = 0; iCounter < items.length; iCounter++) {
      this.zoneList.push(items[iCounter]['id']);
    }
  }
  onZoneDeSelectAll(item) {
    this.zoneList = [];
  }
  setDateFormat(dateArray: any, isYYYYMMDDWithDash) {
    if (dateArray != null) {
      let dd1 = dateArray.getDate().toString();
      if (parseInt(dd1.toString()) < 10)
        dd1 = '0' + dd1.toString();
      let mm1 = (dateArray.getMonth() + 1).toString();
      if (parseInt(mm1.toString()) < 10)
        mm1 = '0' + mm1.toString();
      if (isYYYYMMDDWithDash)
        return dateArray.getFullYear() + '-' + mm1 + '-' + dd1;
      else
        return mm1 + '/' + dd1 + '/' + dateArray.getFullYear();
    }
    else
      return null;
  }
  redirectToOnlineRegistrationList() {
    if (sessionStorage.getItem('isOnlineApplicationHistoryId') != null) {
      sessionStorage.removeItem('isOnlineApplicationHistoryId');
    }
    this.router.navigate(['admin/online/register/user']);
  }
  GetOnlineRegistrationFindById() {
    this.onlineRegisterService.GetOnlineRegistrationFindById(this.onlineRegistrationId).subscribe(
      data => {
        this.registerModel = data;
        if (this.registerModel != null) {
          this.personalDetailsForm.setValue({
            firstName: this.registerModel.firstName,
            middleName: '',
            lastName: this.registerModel.lastName,
            phoneNo: this.registerModel.phone,
            dateofBirth: null,
            emailId: this.registerModel.emailId,
            sSN: '',
            tIN: '',
            chkAgreement: '',
            programName: this.programName
          });
        }
      },
      error => {
        console.log(error);
      }
    );
  }
  confirmReasonFormInit() {
    this.confirmReasonForm = this.fb.group({
      isEnrolledInNLAD: ['No'],
    });
  }
  showhideOtherInformation(value) {
    if (value === 'Yes') {
      this.isAlreadyEnrolledRadioChecked = true;
      this.odf.enrollmentDate.setValidators([Validators.required]);
      this.odf.applicationNumber.setValidators([Validators.required]);
      this.odf.transactionEffectiveDate.setValidators([Validators.required]);
      this.odf.serviceInitializationDate.setValidators([Validators.required]);
      this.odf.eligibilityExpirationDate.setValidators([Validators.required]);
    }
    else {
      this.isAlreadyEnrolledRadioChecked = false;
      this.odf.enrollmentDate.clearValidators();
      this.odf.applicationNumber.clearValidators();
      this.odf.transactionEffectiveDate.clearValidators();
      this.odf.serviceInitializationDate.clearValidators();
      this.odf.eligibilityExpirationDate.clearValidators();
    }
    this.odf.enrollmentDate.updateValueAndValidity();
    this.odf.applicationNumber.updateValueAndValidity();
    this.odf.transactionEffectiveDate.updateValueAndValidity();
    this.odf.serviceInitializationDate.updateValueAndValidity();
    this.odf.eligibilityExpirationDate.updateValueAndValidity();
  }
  checkIsAgree($event) {
    this.isAgree = $event.currentTarget.checked;
  }
  checkTribalLands($event) {
    this.isTribalLands = $event.currentTarget.checked;
  }
  GetResumbitApplicationData() {
    this.subscriberService.reSubmitApplicationFindByApplicationHistoryId(this.resubmitApplicationHistoryId).subscribe(
      data1 => {
        let data = data1.resubmitModel;
        if (data != null) {
          this.cApp.subscriberApplicationHistory.id = data.applicationHistoryModel.id;
          this.cApp.subscriberPersonalDetails.eligibilityExpirationDate = '';
          this.cApp.subscriberPersonalDetails.transactionEffectiveDate = '';
          this.cApp.subscriberPersonalDetails.serviceInitializationDate = '';
          this.cApp.subscriberPersonalDetails.onlineRegistrationID = data.applicationHistoryModel.onlineRegistrationId;
          this.onlineRegistrationId = data.applicationHistoryModel.onlineRegistrationId;
          this.personalDetailsForm.setValue({
            firstName: data.postEnrollJSON.firstName,
            middleName: data.postEnrollJSON.middleName,
            lastName: data.postEnrollJSON.lastName,
            phoneNo: data.postEnrollJSON.phoneNumber,
            dateofBirth: data.dob,
            emailId: data.postEnrollJSON.consumerEmail,
            sSN: data.postEnrollJSON.last4ssn,
            tIN: data.postEnrollJSON.tribalId,
            chkAgreement: true,
            programName: data.programName
          });
        }
        if (data.postEnrollJSON.last4ssn != null) {
          this.ssnValue = data.postEnrollJSON.last4ssn;
          this.tinValue = '';
        }
        else {
          this.ssnValue = '';
          this.tinValue = data.postEnrollJSON.tribalId;
        }
        this.stateShortNameValue = data.stateModel.stateCode
        this.stateShortNameTempValue = data.mailingStateModel.stateCode;
        this.addressForm.setValue({
          streetNumber: data.postEnrollJSON.primaryAddress1,
          city: data.postEnrollJSON.primaryCity,
          stateId: data.stateModel.id,
          zipcode: data.postEnrollJSON.primaryZipCode,
          stateShortName: this.stateShortNameValue,
          stateShortNameTemp: this.stateShortNameTempValue,
          streetNumberTemp: data.postEnrollJSON.mailingAddress1,
          cityTemp: data.postEnrollJSON.mailingCity,
          stateIdTemp: data.mailingStateModel.id,
          zipcodeTemp: data.postEnrollJSON.mailingZipCode,
          addressType: data.postEnrollJSON.primaryAddress1 == data.postEnrollJSON.mailingAddress1 ? 'Mail' : 'Home',
          isTribalLands: data.postEnrollJSON.ebbpTribalBenefitFlag == "1" ? true : false
        });
        this.isTribalLands = data.postEnrollJSON.ebbpTribalBenefitFlag == "1" ? true : false;
        this.isDependent = data.postEnrollJSON.bqpFirstName != '' ? true : false;
        this.globle.cApp.subscriberDependent.isDependent = this.isDependent;
        this.dependentForm.get('isDependent').setValue(this.isDependent);
        if (this.isDependent) {
          this.dependentForm.enable();
        }
        this.dependentSSNValue = data.postEnrollJSON.bqpLast4ssn;
        this.dependentTINValue = data.postEnrollJSON.bqpTribalId;
        this.dependentForm = this.fb.group({
          isDependent: [this.isDependent],
          firstName: data.postEnrollJSON.bqpFirstName,
          middleName: data.postEnrollJSON.bqpMiddleName,
          lastName: data.postEnrollJSON.bqpLastName,
          dateofBirth: data.bqpDob,
          sSN: this.dependentSSNValue,
          tIN: this.dependentTINValue
        });
        if (data.postEnrollJSON.bqpLast4ssn != null) {
          this.dependentSSNValue = data.postEnrollJSON.bqpLast4ssn;
          this.dependentTINValue = '';
          this.onChangDependentSSN('SSN')
        }
        else {
          this.dependentSSNValue = '';
          this.dependentTINValue = data.postEnrollJSON.bqpTribalId;
          this.onChangDependentSSN('')
        }
        // Set Qualify Program
        if (data.qualifyModel.snap == 'true') {
          this.isSNAPChecked = true;
          if (this.isSNAPChecked)
            this.isUncheckEBI = false;
        }
        else if (data.qualifyModel.medicald == 'true') {
          this.isMedicalIdChecked = true;
          if (this.isMedicalIdChecked)
            this.isUncheckEBI = false;
        }
        else if (data.qualifyModel.ssi == 'true') {
          this.isSSIChecked = true;
          if (this.isSSIChecked)
            this.isUncheckEBI = false;
        }
        else if (data.qualifyModel.fpha == 'true') {
          this.isFPHAChecked = true;
          if (this.isFPHAChecked)
            this.isUncheckEBI = false;
        }
        else if (data.qualifyModel.vp == 'true') {
          this.isVPChecked = true;
          if (this.isVPChecked)
            this.isUncheckEBI = false;
        }
        else if (data.qualifyModel.bia == 'true') {
          this.isBIAChecked = true;
          if (this.isBIAChecked)
            this.isUncheckEBI = false;
        }
        else if (data.qualifyModel.tribalTANF == 'true') {
          this.isTribalTANFChecked = true;
          if (this.isTribalTANFChecked)
            this.isUncheckEBI = false;
        }
        else if (data.qualifyModel.fdpir == 'true') {
          this.isFDPIRChecked = true;
          if (this.isFDPIRChecked)
            this.isUncheckEBI = false;
        }
        else if (data.qualifyModel.tribalHS == 'true') {
          this.isTribalHSChecked = true;
          if (this.isTribalHSChecked)
            this.isUncheckEBI = false;
        }
        else if (data.qualifyModel.peasa == 'true') {
          this.isPEASAChecked = true;
          if (this.isPEASAChecked)
            this.isUncheckEBI = false;
        }
        else if (data.qualifyModel.slba == 'true') {
          this.isSLBAChecked = true;
          if (this.isSLBAChecked)
            this.isUncheckEBI = false;
        }
        else if (data.qualifyModel.fpg == 'true') {
          this.isFPGChecked = true;
          if (this.isFPGChecked)
            this.isUncheckEBI = false;
        }
        else if (data.qualifyModel.sloi == 'true') {
          this.isSLOIChecked = true;
          if (this.isSLOIChecked)
            this.isUncheckEBI = false;
        }
        else if (data.qualifyModel.elipCovid19 == 'true') {
          this.isELIPCovid19Checked = true;
          if (this.isELIPCovid19Checked)
            this.isUncheckEBI = false;
        }
        else if (data.qualifyModel.wic == 'true') {
          this.isWICChecked = true;
          if (this.isWICChecked)
            this.isUncheckEBI = false;
        }
        else if (data.qualifyModel.ebi == 'true') {
          if (!this.isSNAPChecked && !this.isMedicalIdChecked && !this.isSSIChecked && !this.isFPHAChecked
            && !this.isVPChecked && !this.isBIAChecked && !this.isTribalTANFChecked && !this.isFDPIRChecked
            && !this.isTribalHSChecked && !this.isPEASAChecked && !this.isSLBAChecked && !this.isFPGChecked
            && !this.isSLOIChecked && !this.isELIPCovid19Checked && !this.isWICChecked
          ) {
            this.isUncheckEBI = true;
          }
          else {
            this.isUncheckEBI = false;
          }
        }
        this.qualifyForm.setValue({
          qualifyType: '1',
          sNAP: this.isSNAPChecked, // E2 = Supplemental Nutrition Assistance Program (SNAP) (Food Stamps)
          medicalId: this.isMedicalIdChecked, // E1 = Medicaid
          sSI: this.isSSIChecked, // E3 = Supplemental Security Income (SSI)
          fPHA: this.isFPHAChecked, // E4 = Federal Public Housing Assistance (FPHA)
          vP: this.isVPChecked, // E15 = Veterans Pension or Survivors Benefit Programs
          bIA: this.isBIAChecked, // E8 = Bureau of Indian Affairs (BIA) General Assistance
          tribalTANF: this.isTribalTANFChecked, // E9 = Tribal Temporary Assistance for Needy Families (Tribal TANF)
          fDPIR: this.isFDPIRChecked, // E10 = Food Distribution Program on Indian Reservations (FDPIR)
          tribalHS: this.isTribalHSChecked, // E11 = Tribal Head Start (only households that meet the income qualifying standard)
          eBI: this.isUncheckEBI, // E13 = I don't participate in one of these programs, I want to qualify through my income.
          isTribalSpecific: false,
          pEASA: this.isPEASAChecked, // E14 – Program Eligibility Approved by State Administrator 
          sLBA: this.isSLBAChecked, // E50 – School Lunch/Breakfast Program
          fPG: this.isFPGChecked, // E51 – Federal Pell Grant
          sLOI: this.isSLOIChecked, // E52 – Substantial Loss of Income
          eLIPCovid19: this.isELIPCovid19Checked, // E53 – Existing low-income program/COVID-19 program
          wIC: this.isWICChecked, // E54 – Special Supplemental Nutrition Program for Women, Infants, and Children (WIC)
        });
        this.otherDetailForm.setValue({
          enrollmentDate: null,
          applicationNumber: data.applicationHistoryModel.apiApplicationId,
          accountNumber: null,
          locationId: null,
          zone: null,
          transactionEffectiveDate: null,
          serviceInitializationDate: null,
          eligibilityExpirationDate: null,
          isConsumerFee: data.postEnrollJSON.consumerFee == "1" ? true : false
        });
        if (data.applicationHistoryModel.apiStatus != "COMPLETE" && data.applicationHistoryModel.apiStatus != "PENDING_CERT") {
          this.isEligiblityError = true;
          if (this.isEligiblityError == true) {
            this.eligiblityErrorMessage = data1.eligibilityerror;
            this.subscriberApplicationData = data1.resubmitModel.applicationHistoryModel;
            this.badRequestError = data1.badRequest;
          }
        }
        else if (data.applicationHistoryModel.apiStatus == "COMPLETE" && data.applicationHistoryModel.apiStatus != "PENDING_CERT") {
          this.isEnrolledError = true;
          if (this.isEnrolledError == true) {
            this.enrolledErrorMessage = JSON.parse(data1.resubmitModel.applicationHistoryModel.enrollJSON);
          }
        }
      },
      error => {
        console.log(error);
      }
    );
  }
  GetEligibilityApplicationFindByApplicationHistoryId() {
    this.subscriberService.GetEligibilityApplicationFindByApplicationHistoryId(this.onlineApplicationHistoryId).subscribe(
      data1 => {
        let data = data1.resubmitModel.eligibllityModel;
        if (data != null) {
          this.cApp.subscriberApplicationHistory.id = data1.resubmitModel.enrollModel.applicationHistoryModel.id;
          this.cApp.subscriberPersonalDetails.eligibilityExpirationDate = '';
          this.cApp.subscriberPersonalDetails.transactionEffectiveDate = '';
          this.cApp.subscriberPersonalDetails.serviceInitializationDate = '';
          this.cApp.subscriberPersonalDetails.onlineRegistrationID = data1.resubmitModel.enrollModel.applicationHistoryModel.onlineRegistrationId;
          this.onlineRegistrationId = data1.resubmitModel.enrollModel.applicationHistoryModel.onlineRegistrationId;
          if (data.subscriberPersonalDetails.program != null && data.subscriberPersonalDetails.program != '' && typeof (data.subscriberPersonalDetails.program) != undefined)
            this.programName = data.subscriberPersonalDetails.program;
          this.personalDetailsForm.patchValue({
            firstName: data.subscriberPersonalDetails.firstName,
            middleName: data.subscriberPersonalDetails.middleName,
            lastName: data.subscriberPersonalDetails.lastName,
            phoneNo: data.subscriberPersonalDetails.phoneNo,
            dateofBirth: data.subscriberPersonalDetails.dateofBirth,
            emailId: data.subscriberPersonalDetails.emailId,
            sSN: data.subscriberPersonalDetails.ssn,
            tIN: data.subscriberPersonalDetails.tin,
            chkAgreement: true,
            programName: this.programName,
          });
        }
        if (data.subscriberPersonalDetails.ssn != null) {
          this.ssnValue = data.subscriberPersonalDetails.ssn;
          this.tinValue = '';
        }
        else {
          this.ssnValue = '';
          this.tinValue = data.subscriberPersonalDetails.tin;
        }
        this.stateShortNameValue = data.subscriberAddress.stateShortName;
        this.stateShortNameTempValue = data.subscriberAddress.stateShortNameTemp;
        this.addressForm.setValue({
          streetNumber: data.subscriberAddress.streetNumber,
          city: data.subscriberAddress.city,
          stateId: data.subscriberAddress.stateId,
          zipcode: data.subscriberAddress.zipcode,
          stateShortName: this.stateShortNameValue,
          stateShortNameTemp: this.stateShortNameTempValue,
          streetNumberTemp: data.subscriberAddress.stateShortNameTemp,
          cityTemp: data.subscriberAddress.cityTemp,
          stateIdTemp: data.subscriberAddress.stateIdTemp,
          zipcodeTemp: data.subscriberAddress.zipcodeTemp,
          addressType: data.subscriberAddress.addressType == data.subscriberAddress.addressType ? 'Mail' : 'Home',
          isTribalLands: data.subscriberAddress.isTribalLands
        });
        this.isTribalLands = data.subscriberAddress.isTribalLands;
        this.isDependent = data.subscriberDependent.firstName != '' ? true : false;
        this.globle.cApp.subscriberDependent.isDependent = this.isDependent;
        this.dependentForm.get('isDependent').setValue(this.isDependent);
        if (this.isDependent) {
          this.dependentForm.enable();
        }
        this.dependentSSNValue = data.subscriberDependent.ssn;
        this.dependentTINValue = data.subscriberDependent.tin;
        this.dependentForm = this.fb.group({
          isDependent: [this.isDependent],
          firstName: data.subscriberDependent.firstName,
          middleName: data.subscriberDependent.middleName,
          lastName: data.subscriberDependent.lastName,
          dateofBirth: data.subscriberDependent.dateofBirth,
          sSN: this.dependentSSNValue,
          tIN: this.dependentTINValue
        });
        if (data.subscriberDependent.ssn != null) {
          this.dependentSSNValue = data.subscriberDependent.ssn;
          this.dependentTINValue = '';
          this.onChangDependentSSN('SSN')
        }
        else {
          this.dependentSSNValue = '';
          this.dependentTINValue = data.subscriberDependent.tin;
          this.onChangDependentSSN('')
        }
        // Set Qualify Program
        if (data.subscriberQualify.snap) {
          this.isSNAPChecked = true;
          if (this.isSNAPChecked)
            this.isUncheckEBI = false;
        }
        else if (data.subscriberQualify.medicalId) {
          this.isMedicalIdChecked = true;
          if (this.isMedicalIdChecked)
            this.isUncheckEBI = false;
        }
        else if (data.subscriberQualify.ssi) {
          this.isSSIChecked = true;
          if (this.isSSIChecked)
            this.isUncheckEBI = false;
        }
        else if (data.subscriberQualify.fpha) {
          this.isFPHAChecked = true;
          if (this.isFPHAChecked)
            this.isUncheckEBI = false;
        }
        else if (data.subscriberQualify.vp) {
          this.isVPChecked = true;
          if (this.isVPChecked)
            this.isUncheckEBI = false;
        }
        else if (data.subscriberQualify.bia) {
          this.isBIAChecked = true;
          if (this.isBIAChecked)
            this.isUncheckEBI = false;
        }
        else if (data.subscriberQualify.tribalTANF) {
          this.isTribalTANFChecked = true;
          if (this.isTribalTANFChecked)
            this.isUncheckEBI = false;
        }
        else if (data.subscriberQualify.fdpir) {
          this.isFDPIRChecked = true;
          if (this.isFDPIRChecked)
            this.isUncheckEBI = false;
        }
        else if (data.subscriberQualify.tribalHS) {
          this.isTribalHSChecked = true;
          if (this.isTribalHSChecked)
            this.isUncheckEBI = false;
        }
        else if (data.subscriberQualify.peasa) {
          this.isPEASAChecked = true;
          if (this.isPEASAChecked)
            this.isUncheckEBI = false;
        }
        else if (data.subscriberQualify.slba) {
          this.isSLBAChecked = true;
          if (this.isSLBAChecked)
            this.isUncheckEBI = false;
        }
        else if (data.subscriberQualify.fpg) {
          this.isFPGChecked = true;
          if (this.isFPGChecked)
            this.isUncheckEBI = false;
        }
        else if (data.subscriberQualify.sloi) {
          this.isSLOIChecked = true;
          if (this.isSLOIChecked)
            this.isUncheckEBI = false;
        }
        else if (data.subscriberQualify.elipCovid19) {
          this.isELIPCovid19Checked = true;
          if (this.isELIPCovid19Checked)
            this.isUncheckEBI = false;
        }
        else if (data.subscriberQualify.wic) {
          this.isWICChecked = true;
          if (this.isWICChecked)
            this.isUncheckEBI = false;
        }
        else if (data.subscriberQualify.ebi) {
          if (!this.isSNAPChecked && !this.isMedicalIdChecked && !this.isSSIChecked && !this.isFPHAChecked
            && !this.isVPChecked && !this.isBIAChecked && !this.isTribalTANFChecked && !this.isFDPIRChecked
            && !this.isTribalHSChecked && !this.isPEASAChecked && !this.isSLBAChecked && !this.isFPGChecked
            && !this.isSLOIChecked && !this.isELIPCovid19Checked && !this.isWICChecked
          ) {
            this.isUncheckEBI = true;
          }
          else {
            this.isUncheckEBI = false;
          }
        }
        this.qualifyForm.setValue({
          qualifyType: '1',
          sNAP: this.isSNAPChecked, // E2 = Supplemental Nutrition Assistance Program (SNAP) (Food Stamps)
          medicalId: this.isMedicalIdChecked, // E1 = Medicaid
          sSI: this.isSSIChecked, // E3 = Supplemental Security Income (SSI)
          fPHA: this.isFPHAChecked, // E4 = Federal Public Housing Assistance (FPHA)
          vP: this.isVPChecked, // E15 = Veterans Pension or Survivors Benefit Programs
          bIA: this.isBIAChecked, // E8 = Bureau of Indian Affairs (BIA) General Assistance
          tribalTANF: this.isTribalTANFChecked, // E9 = Tribal Temporary Assistance for Needy Families (Tribal TANF)
          fDPIR: this.isFDPIRChecked, // E10 = Food Distribution Program on Indian Reservations (FDPIR)
          tribalHS: this.isTribalHSChecked, // E11 = Tribal Head Start (only households that meet the income qualifying standard)
          eBI: this.isUncheckEBI, // E13 = I don't participate in one of these programs, I want to qualify through my income.
          isTribalSpecific: false,
          pEASA: this.isPEASAChecked, // E14 – Program Eligibility Approved by State Administrator 
          sLBA: this.isSLBAChecked, // E50 – School Lunch/Breakfast Program
          fPG: this.isFPGChecked, // E51 – Federal Pell Grant
          sLOI: this.isSLOIChecked, // E52 – Substantial Loss of Income
          eLIPCovid19: this.isELIPCovid19Checked, // E53 – Existing low-income program/COVID-19 program
          wIC: this.isWICChecked, // E54 – Special Supplemental Nutrition Program for Women, Infants, and Children (WIC)
        });
        this.otherDetailForm.setValue({
          enrollmentDate: null,
          applicationNumber: data1.resubmitModel.enrollModel.applicationHistoryModel.apiApplicationId,
          accountNumber: null,
          locationId: null,
          zone: null,
          transactionEffectiveDate: null,
          serviceInitializationDate: null,
          eligibilityExpirationDate: null,
          isConsumerFee: data.subscriberPersonalDetails.isConsumerFee ? true : false
        });
        if (data1.resubmitModel.enrollModel.applicationHistoryModel.apiStatus != "COMPLETE" && data1.resubmitModel.enrollModel.applicationHistoryModel.apiStatus != "PENDING_CERT") {
          this.isEligiblityError = true;
          if (this.isEligiblityError == true) {
            this.eligiblityErrorMessage = data1.eligibilityerror;
            this.subscriberApplicationData = data1.resubmitModel.enrollModel.applicationHistoryModel;
            this.badRequestError = data1.badRequest;
          }
        }
        else if (data1.resubmitModel.enrollModel.applicationHistoryModel.apiStatus == "COMPLETE" && data1.resubmitModel.enrollModel.applicationHistoryModel.apiStatus != "PENDING_CERT") {
          if (data1.resubmitModel.enrollModel.applicationHistoryModel.enrollJSON != null) {
            this.isEnrolledError = true;
            if (this.isEnrolledError == true) {
              this.enrolledErrorMessage = JSON.parse(data1.resubmitModel.enrollModel.applicationHistoryModel.enrollJSON);
            }
          }
        }
      },
      error => {
        console.log(error);
      }
    );
  }
  setProgram(programName: string) {
    this.programName = programName;
  }
}