import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from './data.service';
import { Globle } from 'src/app/Shared/global';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PaginationModel } from 'src/Models/paginationModel';
import { MaintenanceModel } from 'src/Models/maintenanceModel';

@Injectable({
  providedIn: 'root'
})
export class Maintenance {

  constructor(private _http: HttpClient, private _data: DataService, private _global: Globle) {
    this.setAPIUrl();
  }
  AppUrl: string = '';
  setAPIUrl() {
    if (
      sessionStorage.getItem('BaseURL') != null &&
      sessionStorage.getItem('BaseURL') != ''
    ) {
      this.AppUrl = sessionStorage.getItem('BaseURL') || '';
    }
  }

  GetMaintenances(pagination: PaginationModel): Observable<any> {
    return this._data.post(`Maintenance/GetMaintenances`, pagination)
      .pipe(map(response => {
        return response;
      }));
  }

  AddUpdateMaintenance(model: MaintenanceModel): Observable<any> {
    return this._data.post(`Maintenance/AddUpdateMaintenance`, model)
      .pipe(map(response => {
        return response;
      }));
  }

  GetMaintenanceById(id: any): Observable<any> {
    return this._data.post(`Maintenance/GetMaintenanceById`, id)
      .pipe(map(response => {
        return response;
      }));
  }
}
