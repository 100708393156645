export class ProviderModel {
  id: number;
  name: string;
  address: string;
  stateId: number;
  city: string;
  zipcode: string;
  emailId: string;
  webUrl: string;
  isActive: string;
  divisionCount?: number;
  userCount?: number;
  totalRecordCount?: number;
  ModifiedByName: string;
}

export class NewRegistrationModel {
  id: number;
  firstName: string;
  middleName: string;
  lastName: string;
  phoneNo: string;
  emailId: string;
  applicationStatus: number;
  customerId: string;
  locationId: string;
  createdDate?: string;
  modifiedDate?: string;
  message: string;
  newapplicationStatus?: number;
  totalRecords?: number;
  totalMessage?: number;
  totalDocument?: number;
  pdF_FilePath: string;
}

