
export class MaintenanceModel {
    id: number;
    messageText: string;
    providerId: number;
    startDate: Date;
    startTime: Date;
    endDate: Date;
    endTime: Date;
    startDateString: string;
    endDateString: string;
    status: string;
    isActive: string;
    createdBy: number;
    createdDate: Date;
    modifiedBy: number;
    modifiedDate: Date;
    statusName: string;
    totalRecordCount: any    
}