export class ProviderZoneModel {
    id: any;
    zoneCode: string;
    LocationId: any;
    isActive: any;
    createdBy: any;
    createdDate: any;
    modifiedBy: any;
    modifiedDate: any;
    isSelected: any;
    providerId: any;
}