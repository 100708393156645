import { Component, OnInit } from '@angular/core';
import { Maintenance } from 'src/Services/maintenance.services';
import { MaintenanceModel } from 'src/Models/maintenanceModel';
import { Router, ActivatedRoute } from '@angular/router';
import { movetotop, getIntParam, checkContainesURL, getEncryptedString } from 'src/app/Shared/commonMethods';
import { Title } from '@angular/platform-browser';
import { Globle } from 'src/app/Shared/global';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'src/app/Shared/toastr.service';
import { AuthService } from 'src/app/Shared/auth.service';
import { getDate } from 'ngx-bootstrap/chronos/utils/date-getters';

@Component({
  selector: 'app-manage-maintenance',
  templateUrl: './manage-maintenance.component.html',
  styleUrls: ['./manage-maintenance.component.css']
})
export class ManageMaintenanceComponent implements OnInit {
  maintenanceForm: FormGroup;
  fromProvider: boolean = false;
  providerId = 0;
  id = 0;
  isloading: boolean = false;
  model = new MaintenanceModel();
  submitted = false;
  startMinDate;
  endMinDate;
  isDisabled = false;
  startValid = true;
  endValid = true;
  constructor(
    private toastr: ToastrService,
    private _fb: FormBuilder,
    private maintenance: Maintenance,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private auth: AuthService,
    private globle: Globle) {
    this.maintenanceForm = this._fb.group({
      id: [this.id],
      startDate: ['', [Validators.required]],
      startTime: ['', [Validators.required]],
      endDate: ['', [Validators.required]],
      endTime: ['', [Validators.required]],
      messageText: ['', [Validators.required]],
      isActive: 1,
      providerId: this.providerId,
      status: 1,
    });
  }

  ngOnInit() {
    this.titleService.setTitle('Manage Maintenance');
    this.fromProvider = checkContainesURL(this.router, 'providers');
    this.providerId = getIntParam(this.activatedRoute.params, 'pid');
    this.id = getIntParam(this.activatedRoute.params, 'id');
    this.startMinDate = this.endMinDate = new Date();

    if (!this.fromProvider) {
      this.providerId = this.globle.cUser.providerId;
    }

    if (this.id) {
      this.GetMaintenance();
    }
    else {
    }
  }

  get f() {
    return this.maintenanceForm.controls;
  }

  redirectToMaintenances() {
    this.router.navigate(['/admin/maintenances']);
  }

  GetMaintenance() {
    this.isloading = true;
    this.maintenance.GetMaintenanceById(this.id).subscribe(
      data => {
        this.model = data;
        this.model.startTime = this.model.startDate;
        this.model.endTime = this.model.endDate;
        this.maintenanceForm.patchValue(this.model);
        this.isDisabled = (new Date(this.model.endDate) < new Date() == true ? true : false)
        this.isloading = false;
      }
    );
  }

  changeStartDate(value: Date) {
    if (!this.isDisabled)
      this.endMinDate = value;
  }

  saveMaintenances(maintenanceForm: any) {
    this.submitted = true;
    if (this.auth.checkValidation(maintenanceForm)) {
      this.model = maintenanceForm.value
      this.isloading = true;


      this.model.startDate = this.mergeDateTime(maintenanceForm.value.startDate, maintenanceForm.value.startTime)
      this.model.endDate = this.mergeDateTime(maintenanceForm.value.endDate, maintenanceForm.value.endTime)
      if (this.model.startDate > this.model.endDate || this.model.startDate.toString() == this.model.endDate.toString()) {
        this.toastr.Error("Start date must be less than of end date.", "Error");
        return;
      }
      if (new Date(this.model.startDate) < new Date() && this.id == 0) {
        this.toastr.Error("Start time must be greater than of current tiime.", "Error");
        return;
      }
      this.model.startDateString = this.mergeDateTimeString(maintenanceForm.value.startDate, maintenanceForm.value.startTime)
      this.model.endDateString = this.mergeDateTimeString(maintenanceForm.value.endDate, maintenanceForm.value.endTime)

      this.model.modifiedBy = this.globle.cUser.id;
      this.model.createdBy = this.globle.cUser.id;
      this.model.providerId = this.providerId;
      this.maintenance.AddUpdateMaintenance(this.model).subscribe(
        data => {
          if (this.model.id > 0)
            this.toastr.Success("Record added successfully.", "Success");
          else
            this.toastr.Success("Record updated successfully.", "Success");
          this.redirectToMaintenances();
          this.isloading = false;
        }
      );
    }
  }

  mergeDateTime(date, dttime) {
    const time = dttime.toLocaleTimeString('it-IT');
    const yy = new Date(date).getFullYear();
    const mm = new Date(date).getMonth() + 1;
    const dd = new Date(date).getDate();

    var interMedDt = new Date(mm + '-' + dd + '-' + yy);
    interMedDt.setHours((time.split(' ')[0]).split(':')[0]);
    interMedDt.setMinutes((time.split(' ')[0]).split(':')[1]);
    return interMedDt;
  }

  mergeDateTimeString(date, dttime) {
    const time = dttime.toLocaleTimeString('it-IT');
    const yy = new Date(date).getFullYear();
    const mm = new Date(date).getMonth() + 1;
    const dd = new Date(date).getDate();
    return yy + '-' + mm + '-' + dd + ' ' + (time.split(' ')[0]).split(':')[0] + ':' + (time.split(' ')[0]).split(':')[1];
  }
}
