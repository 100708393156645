import { Component, OnInit } from '@angular/core';
import { UserRightsService } from 'src/Services/userrights.service';
import { Globle } from 'src/app/Shared/global';
import { TreeNode } from 'primeng/api';
import { NodeService } from 'src/Services/node.service';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-userrights',
  templateUrl: './userrights.component.html',
  styleUrls: ['./userrights.component.css']
})
export class UserrightsComponent implements OnInit {
  isloading = false;
  isloadingSpin = false;

  activeGroup: number;
  listOfModules: Array<any> = [];
  listOfUserGroup: Array<any> = [];
  selectedRights: Array<any> = [];
  displayModules: Array<any> = [];

  constructor
    (
      private userRightsService: UserRightsService,
      private globle: Globle,
      private nodeService: NodeService,
      private toastr: ToastrService,
      private titleService: Title,
    ) {
    this.titleService.setTitle('System Access');
  }

  ngOnInit() {
    this.getUserGroups(this.globle.cUser.userTypeId)
  }

  // Get All Groups 
  getUserGroups(userGroupId: number): void {
    this.isloading = true;
    this.userRightsService.getUserGroups(userGroupId).subscribe(
      data => {
        this.listOfUserGroup = data.userGroups;
        if (this.listOfUserGroup.length > 0) {
          this.listOfUserGroup = this.listOfUserGroup.filter(x => x.id != this.globle.cUser.userGroupId);
          this.activeGroup = Number(this.listOfUserGroup[0].id);
          // Get All Modules 
          this.getModules(this.globle.cUser.userTypeId)
        }
        this.isloading = false;
      }
    );
  }

  // Get All Modules 
  getModules(userTypeId: number): void {
    this.isloadingSpin = true;
    this.userRightsService.getModules(userTypeId).subscribe(
      data => {
        if (data.length > 0) {
          this.listOfModules = data.filter(x => x.parentId == 0);
          if (this.listOfUserGroup.length > 0) {
            // Make clone of modules, group wise 
            this.listOfUserGroup.forEach((group: any) => {
              this.listOfModules.forEach((module: any) => {
                this.selectedRights.push({ userGroupId: group.id, moduleId: module.id, moduleName: module.name, isSelected: false });
              });
            });
            // Get all group ids
            var groupIds = this.listOfUserGroup.map(x => x.id).join('_');

            // Get Selected Rights at the time of page load
            this.getUserRightForSelectedGroup(groupIds);

            // Select default groupid for modules
            this.onUserGroupSelection(this.activeGroup)
          }
        }
        this.isloadingSpin = false;
      }
    );
  }

  // Get Selected Rights at the time of page load
  getUserRightForSelectedGroup(userGroupIds: string): void {
    this.isloadingSpin = true;
    this.userRightsService.getUserRightForSelectedGroup(userGroupIds).subscribe(
      data => {
        data.forEach(element => {
          this.selectedRights.filter(x => x.userGroupId == element.UserGroupId && x.moduleId == element.Id).forEach(x => {
            x.isSelected = true
          });
        });
        this.isloadingSpin = false;
      },
      error => {
        this.toastr.error("Please try again later.", "Error");
        this.isloadingSpin = false;
      });
  }

  // Selection change event for User Group
  onUserGroupSelection(groupId) {
    this.activeGroup = groupId;
    this.displayModules = this.selectedRights.filter(x => x.userGroupId == this.activeGroup);
  }

  // Check change event for Modules
  onModuleCheckChange(moduleId) {
    for (const key in this.selectedRights) {
      break;
    }
  }

  // Save user rights
  onClickSave() {
    // Make deep copy of selected modules
    var makeDeepCopy = JSON.stringify(this.selectedRights.filter(x => x.isSelected));
    var parser = JSON.parse(makeDeepCopy);

    // Remove unwanted entity from object - selectedRights 
    parser = parser.filter(function (element) {
      delete element.isSelected;
      delete element.moduleName;
      return true;
    });

    this.isloadingSpin = true;

    // Save selected rights
    this.userRightsService.addUpdateUserRights({ userId: this.globle.cUser.id, rightsModels: parser }).subscribe(
      data => {
        if (data) {
          if (data == 1) {

            this.toastr.success("System access has been saved successfully.", "Success");
          }
          else {
            this.toastr.error("Please try again later.", "Error");
          }
          this.isloadingSpin = false;
        }
      }, error => {
        this.toastr.error("Please try again later.", "Error");
        this.isloadingSpin = false;
      });
  }

}