import { Injectable } from '@angular/core';
import { Http, RequestMethod, ResponseContentType, Headers } from '@angular/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DataService } from './data.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class ApplicantService {

    constructor(private _data: DataService, private _http: Http, private _httpClient: HttpClient) { }
    UploadExcelFile(formData: FormData): Observable<any> {
        const httpOptions = { headers: new HttpHeaders({ "Accept": "application/json" }) };
        return this._data.filePostFromData(`ApplicantImport/UploadExcel`, formData, httpOptions)
            .pipe(map(response => {
                return response;
            }));
    }

    DownloadDocument(TokenKey, path: any): Observable<any> {
        let data = 'ApplicantImport/DownloadDocument';
        return this._http.post(`${environment.apiURL}api/` + data, path, {
            method: RequestMethod.Post, responseType: ResponseContentType.Blob,
            headers: new Headers({ 'Token': TokenKey })
        }).pipe(
            map(
                (res) => {
                    return new Blob([res.blob()], { type: 'application/octet-stream' });
                }));
    }
}
