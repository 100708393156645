import { DataService } from './data.service';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, debounce } from 'rxjs/operators';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { environment } from 'src/environments/environment';
import { Http, Response, ResponseContentType } from '@angular/http';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private _data: DataService) { }

  DetailReport(ReportFilterModel): Observable<any> {
    return this._data.post(`Report/DetailReport`, ReportFilterModel)
      .pipe(map(response => {
        return response;
      }));
  }

  AnalyticReport(filter): Observable<any> {
    return this._data.post(`Report/AnalyticReport`, filter)
      .pipe(map(response => {
        return response;
      }));
  }

  GetApiStatus(): Observable<any> {
    return this._data.post(`Report/GetApiStatus`)
      .pipe(map(response => {
        return response;
      }));
  }
}
