import { Injectable } from '@angular/core';
import { Http, ResponseContentType } from '@angular/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class HtmlToPdfService {

  constructor(private _data: DataService, private _http: Http) { }

  CreatePDF(hTMLContent: string, chartImage: string): Observable<any> {
    return this._data.post('MyPdf/GetPDF', { hTMLContent: hTMLContent, chartImage: chartImage })
      .pipe(map(response => {
        return response;
      }));
  }

  DeletePDF(FileName: string): Observable<any> {
    return this._data.post('MyPdf/DeletePDFFile', { fileName: FileName })
      .pipe(map(response => {
        return response;
      }));
  }

  public downloadPdf(url): Observable<any> {
    return this._http.get(url, { responseType: ResponseContentType.Blob }).pipe(map(
      (res) => {
        return new Blob([res.blob()], { type: 'application/pdf' });
      }));
  }
}
