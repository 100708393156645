import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Globle } from 'src/app/Shared/global';
import { monthlyreportFilterModel } from 'src/Models/monthlyreportFilterModel';
import { SnapshotandSummaryReportModel } from 'src/Models/SnapshotandSummaryReportModel';
import { UsersService } from 'src/Services/users.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ElementRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import * as FileSaver from 'file-saver';
import { UniversalService } from 'src/Services/universal.service';
import { Inject } from '@angular/core';

@Component({
  selector: 'app-nlad-vs-inroll',
  templateUrl: './nlad-vs-inroll.component.html',
  styleUrls: ['./nlad-vs-inroll.component.css']
})
export class NladVSInrollComponent implements OnInit {
  filterModel = new monthlyreportFilterModel();
  exportFilterModel = new monthlyreportFilterModel();
  recordsCount: number = -1;
  currentPage: number = 1;
  isloading = false;
  NladButNotInrollDetailsList: any = [];
  listOfExports: any = [];
  totalRecordsCount: number = 0;

  upDownsubscriberIdCSS: string = '';
  upDownlastNameCSS: string = '';
  upDownfirstNameCSS: string = '';
  upDownemailIdCSS: string = '';
  isAscending: boolean;

  constructor(private _fb: FormBuilder, private router: Router, private userService: UsersService, private titleService: Title,
    private globle: Globle, private toastr: ToastrService) {
    this.filterModel.pageNumber = 1;
    this.filterModel.pageSize = 10;
    this.filterModel.monthandyear = "";
    this.filterModel.orderBy = 'subscriberId desc';
    this.titleService.setTitle('Total Active Subscribers Difference of Inroll vs NLAD');
  }

  ngOnInit() {
    this.GetNladVSInrollReport();
  }

  BacktoList() {
    sessionStorage.setItem('snapshotPeriod', '');
    sessionStorage.setItem('monthandyear', '');
    this.router.navigateByUrl(`admin/snapshot-summary-report`);
  }

  GetNladVSInrollReport() {
    this.isloading = true;
    this.NladButNotInrollDetailsList = [];

    this.userService.GetNladVSInrollReport(this.filterModel).subscribe(
      data => {

        this.exportFilterModel = Object.assign({}, this.filterModel);
        this.exportFilterModel.pageSize = 100000;
        this.exportFilterModel.pageNumber = 1;

        this.isloading = false;
        this.NladButNotInrollDetailsList = data;

        this.isloading = false;
        this.NladButNotInrollDetailsList = data;
        if (this.NladButNotInrollDetailsList != null) {
          if (this.NladButNotInrollDetailsList.length > 0) {
            this.totalRecordsCount = this.NladButNotInrollDetailsList[0].totalRecordCount;
            this.recordsCount = this.totalRecordsCount;
          }
          else {
            this.totalRecordsCount = 0;
            this.recordsCount = 0;
          }
        }
        else {
          this.totalRecordsCount = 0;
          this.recordsCount = 0;
        }

      },
      error => {
        this.isloading = false;
        console.log(error);
      }
    );
  }

  sort(sortBy: any) {
    this.cleanCssClass();
    this.isAscending = !this.isAscending;
    this.filterModel.orderBy = this.isAscending ? sortBy : sortBy + ' DESC';

    switch (sortBy) {
      case 'subscriberId': this.upDownsubscriberIdCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'lastName': this.upDownlastNameCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'firstName': this.upDownfirstNameCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'emailId': this.upDownemailIdCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      default: break;
    }
    this.GetNladVSInrollReport();
  }

  cleanCssClass() {
    this.upDownsubscriberIdCSS = '';
    this.upDownlastNameCSS = '';
    this.upDownfirstNameCSS = '';
    this.upDownemailIdCSS = '';
  }

  ExportDetails() {
    this.isloading = true;
    this.listOfExports = [];
    this.userService.GetNladVSInrollReport(this.exportFilterModel).subscribe(
      data => {
        this.listOfExports = data;
        setTimeout(this.Export, 3000);
        this.isloading = false;
      },
      error => {
        console.log(error);
      }
    );
  }

  Export() {
    $('#idexportToExcel')[0].click();
  }

  exportToExcel() {
    let tableData = document.getElementById("ExportTable").innerHTML;

    var uri = 'data:application/vnd.ms-excel;base64,'
      , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body>{table}</body></html>'
      , base64 = function (s) { return window.btoa(unescape(encodeURIComponent(s))) }
      , format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) }
    let html = "<table><tr class='mainhead'> <td colspan='3'>";
    html += tableData;
    html += "</td></tr></table>";
    let name = 'GetNladVSInrollReport';
    var ctx = { worksheet: name || 'Worksheet', table: html }
    FileSaver.saveAs((uri + base64(format(template, ctx))), "GetNladVSInrollReport.xls");
  }

  pageChanged($event) {
    this.filterModel.pageNumber = $event.page;
    this.GetNladVSInrollReport();
  }
}
