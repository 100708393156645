import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserRightsService {

  private currentUserAccessSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;

  constructor(private _data: DataService) {
    this.currentUserAccessSubject = new BehaviorSubject<any>(JSON.parse(sessionStorage.getItem('cUserAccess')));
    this.currentUser = this.currentUserAccessSubject.asObservable();
  }

  public get currentUserAccessValue(): any {
    return this.currentUserAccessSubject.value;
  }

  getUserGroups(userTypeId: number): Observable<any> {
    return this._data.get(`Users/GetUserGroups`, userTypeId)
      .pipe(map(response => {
        return response;
      }));
  }

  getModules(userTypeId: any): Observable<any> {
    return this._data.get(`Users/GetModules/` + userTypeId)
      .pipe(map(response => {
        return response;
      }));
  }

  addUpdateUserRights(userRight): Observable<any> {
    return this._data.post(`Users/AddUpdateUserRights`, userRight)
      .pipe(map(response => {
        return response;
      }));
  }

  getUserRightForSelectedGroup(userGroupIds: string): Observable<any> {
    return this._data.get(`Users/GetUserRightForSelectedGroup/${userGroupIds}`)
      .pipe(map(response => {
        return response;
      }));
  }

  getMenuByGroupId(userGroupId: number): Observable<any> {
    return this._data.get(`Users/GetMenuByGroupId/${userGroupId}`)
      .pipe(map(response => {
        sessionStorage.setItem('cUserAccess', JSON.stringify(response));
        this.currentUserAccessSubject.next(response);
        return response;
      }));
  }
}
