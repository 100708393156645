export class InvoiceModel {
    id: number;
    providerId: any;
    invoiceNo: string;
    invoiceDate: any;
    invoiceDateString: any;
    billingStatus: any;
    createdBy: number;
    createdDate: any;
    modifiedBy: number;
    modifiedDate: any;
    createdByName: any;
    modifiedByName: any;
    providerName: any;
    totalRecordCount: any;
    invoiceAmount: any;
}