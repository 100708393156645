import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Globle } from 'src/app/Shared/global';
import { Router, ActivatedRoute } from '@angular/router';
import { ApplicationStatusModel } from 'src/Models/ApplicationStatusModel';
import { ToastrService } from 'ngx-toastr';
import { UsersService } from 'src/Services/users.service';
import { SubscriberMasterModel } from 'src/Models/SubscriberMaster.model';
import * as FileSaver from 'file-saver';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-view-application',
  templateUrl: './view-application.component.html',
  styleUrls: ['./view-application.component.css']
})
export class ViewApplicationComponent implements OnInit {
  applicationStatusModel = new ApplicationStatusModel();
  subscriberModel = new SubscriberMasterModel()
  classEnrollJSON: any;
  appHistioryId: number = 0;
  providerId: number = 0;
  isloading = false;

  isDOBShow: boolean = false;
  enableConsentDateTime=false;

  constructor(private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private userService: UsersService,
    private titleService: Title,
    private globle: Globle,
  ) {
    this.enableConsentDateTime=environment.enableconsentdatetime;
    if (sessionStorage.getItem('viewId') != null)
      this.appHistioryId = +sessionStorage.getItem('viewId');
    this.providerId = this.globle.cUser.providerId;
    this.titleService.setTitle('View Application');

    if (this.globle.cUser.userGroupId == 2 || this.globle.cUser.userGroupId == 5)
      this.isDOBShow = false;
    else
      this.isDOBShow = true;
  }

  ngOnInit() {
    this.GetSubscriberDetails();
  }

  GetSubscriberDetails() {
    this.isloading = true;
    this.userService.GetSubscriberDetails(this.appHistioryId).subscribe(
      data => {
        this.isloading = false;
        this.applicationStatusModel = data;
        if (data.classEnrollJSON != null) {
          let obj = JSON.parse(data.classEnrollJSON);
          this.subscriberModel = obj.subscriberMasterModel;
        }
      },
      error => {
        this.isloading = false;
        console.log(error);
      }
    );
  }

  returnToList() {
    if (sessionStorage.getItem('viewId') != null)
      sessionStorage.removeItem('viewId')
    this.router.navigate(['/admin/applicants'])
  }

 
}
